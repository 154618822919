import * as Types from 'core/types';
import { useMutation, UseMutationOptions } from '@tanstack/react-query';

import { fetchPartnerActiveTariffGroupUpdate } from '../fetches';
import { Tariff } from '../types';

export function usePartnerActiveTariffGroupUpdateMutation(
  options?: UseMutationOptions<
    Tariff,
    Types.HttpError,
    Parameters<typeof fetchPartnerActiveTariffGroupUpdate>
  >,
) {
  return useMutation((args) => fetchPartnerActiveTariffGroupUpdate(...args), {
    retry: 0,
    ...options,
  });
}
