import * as Types from 'core/types';
import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { fetchPartnerTariffGroupTariff } from 'features/partners-tariffs-groups/fetches/fetchPartnerTariffGroupTariff';

import { Tariff } from '../types';

export const USE_PARTNER_TARIFF_GROUP_TARIFF_QUERY_KEY =
  'partners.tariffs.group.tariff';

export function usePartnerTariffGroupTariffQuery(
  params: Parameters<typeof fetchPartnerTariffGroupTariff>[0],
  options?: UseQueryOptions<Tariff, Types.HttpError, Tariff, any>,
) {
  return useQuery(
    [USE_PARTNER_TARIFF_GROUP_TARIFF_QUERY_KEY, params],
    () => fetchPartnerTariffGroupTariff(params),
    {
      refetchOnWindowFocus: false,
      refetchInterval: false,
      refetchOnMount: true,
      refetchOnReconnect: false,
      refetchIntervalInBackground: false,
      ...options,
    },
  );
}
