import {
  FC,
  memo,
  useState,
  useCallback,
  useEffect,
  useRef,
  useMemo,
} from 'react';
import { Col, Row, Form, AutoComplete, InputNumber, FormInstance } from 'antd';
import { useTranslation } from 'react-i18next';
import { useDebouncedCallback } from 'use-debounce';
import { YMapsApi } from '@pbe/react-yandex-maps/typings/util/typing';

import { Coordinates } from '../YandexMap/types';
import { YandexMap } from '../YandexMap/YandexMap';
import {
  getAddressByCoordinates,
  getCoordinatesByAddress,
  getSuggestionsOptions,
} from '../YandexMap/utils';

type Props = {
  form: FormInstance;
  onLoading: (value: boolean) => void;
  coordinates: Coordinates;
  setIsFormChanged: (value: boolean) => void;
};

type Option = { value: string; placeId?: string };

const defaultCoordinates = {
  lat: 0,
  lng: 0,
};

const Address: FC<Props> = ({
  form,
  onLoading,
  coordinates,
  setIsFormChanged,
}) => {
  const lat = parseFloat(Form.useWatch('lat', form) ?? '0');
  const lon = parseFloat(Form.useWatch('lon', form) ?? '0');

  const mapApiRef = useRef<YMapsApi | undefined>();

  const { t } = useTranslation();

  const [autoCompleteOptions, setAutoCompleteOptions] = useState<Option[]>([]);
  const fetchMapAddress = useDebouncedCallback(
    async (coordinates: Coordinates) => {
      if (mapApiRef.current) {
        try {
          const coords = [coordinates.lat, coordinates.lng] as const;
          const address = await getAddressByCoordinates(
            coords,
            mapApiRef.current,
          );
          form.setFieldValue('address', address);
          setIsFormChanged(true);
        } catch (error) {
          console.error(error);
        }
      }
    },
    500,
  );

  useEffect(() => {
    if (lat !== 0 && lon !== 0) {
      fetchMapAddress({ lat, lng: lon });
    }
  }, [lat, lon, fetchMapAddress, coordinates]);

  const handleSearchMap = useDebouncedCallback((value: string) => {
    if (mapApiRef.current) {
      getSuggestionsOptions(value, mapApiRef.current).then((options) => {
        setAutoCompleteOptions(options);
      });
    }
  }, 500);

  const handleSelectLocation = useCallback(
    async ({ value }: Option) => {
      if (mapApiRef.current) {
        try {
          const coordinates = await getCoordinatesByAddress(
            value,
            mapApiRef.current,
          );
          form.setFieldValue('lat', coordinates[0]);
          form.setFieldValue('lon', coordinates[1]);
        } catch (error) {}
      }
    },
    [form],
  );

  const markerCoordinates = useMemo(
    () => ({
      lat,
      lng: lon,
    }),
    [lat, lon],
  );

  return (
    <Col span={12} offset={2}>
      <Row>
        <Form.Item
          name="address"
          label={`${t('locations.labels.address')}:`}
          style={{ width: '100%' }}
        >
          <AutoComplete
            options={autoCompleteOptions}
            placeholder={t('locations.placeholders.enter-address')}
            onChange={handleSearchMap}
            onSelect={(_, option) => handleSelectLocation(option)}
          />
        </Form.Item>
      </Row>

      <Row>
        <Form.Item label={`${t('locations.labels.latitude')}:`} name="lat">
          <InputNumber
            placeholder={t('locations.placeholders.enter-latitude')}
            min={0}
            style={{ width: '100%' }}
          />
        </Form.Item>
      </Row>

      <Row>
        <Form.Item label={`${t('locations.labels.longitude')}:`} name="lon">
          <InputNumber
            placeholder={t('locations.placeholders.enter-longitude')}
            min={0}
            style={{ width: '100%' }}
          />
        </Form.Item>
      </Row>

      <Row>
        <YandexMap
          initialCoordinates={{
            lat: coordinates.lat ?? defaultCoordinates.lat,
            lng: coordinates.lng ?? defaultCoordinates.lng,
          }}
          markerCoordinates={markerCoordinates}
          onChangeCoordinates={({ lat, lng }) => {
            form.setFieldValue('lat', lat);
            form.setFieldValue('lon', lng);
            setIsFormChanged(true);
          }}
          onReady={(apiRef) => {
            mapApiRef.current = apiRef as YMapsApi;
          }}
        />
      </Row>
    </Col>
  );
};

export default memo(Address);
