import { FC, ReactNode, memo } from 'react';
import { useTranslation } from 'react-i18next';
import { Tariff } from 'features/partners-tariffs-groups/types';
import { Table, Typography, Tag, Button } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { Link } from 'react-router-dom';
import { formatDateTimeShort } from 'core/utils';
import {
  getTariffStatus,
  tariffStatusColors,
  TariffStatus,
  tariffStatusLabels,
} from 'core/utils/tariffUtils';

type Props = {
  list: Tariff[];
  onArchive: (tariff: Tariff) => void;
  onActivate: (tariff: Tariff) => void;
  onDelete: (tariff: Tariff) => void;
  onCopy: (tariffId: Tariff['id']) => void;
};

const TariffsList: FC<Props> = ({
  list,
  onActivate,
  onArchive,
  onDelete,
  onCopy,
}) => {
  const { t } = useTranslation();

  const columns: ColumnsType<Tariff & { key: string }> = [
    {
      dataIndex: 'name',
      key: 'name',
      render: (name: string, item) => (
        <Link to={`tariff/${item.id}`}>
          <Typography.Title level={5} style={{ margin: 0 }}>
            {name || item.id}
          </Typography.Title>
        </Link>
      ),
    },
    {
      render: (_, item) => {
        const status = getTariffStatus(item);
        return (
          <Tag color={tariffStatusColors[status]}>
            {t(tariffStatusLabels[status])}
          </Tag>
        );
      },
    },
    {
      dataIndex: 'currency',
      key: 'currency',
    },
    {
      dataIndex: 'validFrom',
      key: 'validFrom',
      render: formatDateTimeShort,
    },
    {
      dataIndex: 'validTo',
      key: 'validTo',
      render: formatDateTimeShort,
    },
    {
      render: (_, item) => {
        const status = getTariffStatus(item);
        if (status === TariffStatus.active) {
          return (
            <>
              <Button type={'link'} onClick={() => onArchive(item)}>
                {t('pricing.actions.to-archive')}
              </Button>
              <Button type={'link'} onClick={() => onCopy(item.id)}>
                {t('pricing.actions.copy')}
              </Button>
            </>
          );
        }
        if (status === TariffStatus.draft) {
          return (
            <>
              <Button type={'link'} onClick={() => onActivate(item)}>
                {t('pricing.actions.activate')}
              </Button>
              <Button type={'link'} onClick={() => onDelete(item)}>
                {t('common.actions.delete')}
              </Button>
              <Button type={'link'} onClick={() => onCopy(item.id)}>
                {t('pricing.actions.copy')}
              </Button>
            </>
          );
        }
      },
    },
  ];

  return (
    <Table
      size="small"
      columns={columns}
      dataSource={list.map((item) => ({
        key: item.id,
        ...item,
      }))}
      scroll={{ x: 500 }}
      pagination={false}
    />
  );
};

export default memo(TariffsList);
