import { Card } from 'antd';
import { usePartnerId } from 'core/providers/PartnerProvider';
import { usePartnerHashtagsQuery } from 'features/hashtags/queries';

import { EnergyPriceRow } from './EnergyPriceRow';
import { TariffConditionsTitle } from './TariffConditionsTitle';
import { TimeRangeInput } from './TimeRangeInput';
import { DaysSelector } from './DaysSelector';
import { TagHashTagsSelector } from './ChargepointHashTagsSelector';

export const SimpleFormInputs = () => {
  const partnerId = usePartnerId();
  const hashtagsQuery = usePartnerHashtagsQuery([partnerId], {
    enabled: !!partnerId,
  });

  return (
    <>
      <Card className="main-section" bordered={false}>
        <EnergyPriceRow />
      </Card>
      <TariffConditionsTitle />

      <TagHashTagsSelector hashtags={hashtagsQuery.data ?? []} />

      <DaysSelector />

      <TimeRangeInput />
    </>
  );
};
