import { Form, Select } from 'antd';
import { useTranslation } from 'react-i18next';
import { connectorTypesList } from 'features/charge-points/types';
import { connectorsLabels } from 'components/view/ConnectorLabel/connectors-labels.const';

import { RowWithLabel } from './RowWithLabel';

export const ConnectorTypesSelector = () => {
  const { t } = useTranslation();

  return (
    <RowWithLabel label={`${t('pricing.labels.connector-types')}:`}>
      <Form.Item name={['restriction', 'connectorTypes']}>
        <Select
          mode="tags"
          maxTagCount={4}
          allowClear
          placeholder={t('pricing.placeholders.select-connector-types')}
          options={connectorTypesList.map((connector) => ({
            label: connectorsLabels[connector],
            value: connector,
          }))}
        />
      </Form.Item>
    </RowWithLabel>
  );
};
