import React, { createContext, useContext } from 'react';

export interface TariffDetailsContextType {
  tariffCurrency: string;
  isActiveTariff: boolean;
  isArchivedTariff: boolean;
}

const TariffDetailsContext = createContext<TariffDetailsContextType>({
  tariffCurrency: '',
  isActiveTariff: false,
  isArchivedTariff: false,
});

const useTariffDetailsContextInternal = () => useContext(TariffDetailsContext);

export const TariffDetailsProvider = ({
  value,
  children,
}: {
  children: React.ReactNode;
  value: TariffDetailsContextType;
}) => {
  return (
    <TariffDetailsContext.Provider value={value}>
      {children}
    </TariffDetailsContext.Provider>
  );
};

export const useTariffDetailsContext = () => {
  const context = useTariffDetailsContextInternal();

  if (!context) {
    throw new Error(
      'useTariffDetailsContextHook must be used within a TariffDetailsContextProvider',
    );
  }

  return context;
};
