import * as Services from 'core/services';
import * as Types from 'core/types';
import { AuthHelpers } from 'features/auth';

import { TariffParams } from '../types';

export async function fetchPartnerTariffGroupTariffDelete(
  params: TariffParams,
) {
  try {
    const header = await AuthHelpers.authHeader();
    const response = await Services.apiService.delete(
      `/partners/${params.partnerId}/tariffs/groups/${params.groupId}/tariffs/${params.tariffId}`,
      { headers: { ...header } },
    );
    return response.data;
  } catch (error: any) {
    if (!error.response) {
      throw error;
    }
    return Promise.reject(error.response.data as Types.HttpError);
  }
}
