import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Form, Input, Select, Button, Radio, Row, Col, Checkbox } from 'antd';
import {
  getConnectorsSelectOptions,
  getPowerTypeSelectOptions,
} from 'components/view/ConnectorLabel/connectors-labels.const';
import { ModalFooter } from 'components/view/ModalFooter';
import { NamedConnector } from 'core/types/Connector';

import s from './ConnectorEditForm.module.css';

interface IProps {
  connectorUpdate: NamedConnector;
  onSubmit: (val: NamedConnector) => void;
  onClose: () => void;
  isLoading: boolean;
}

const ConnectorEditForm: FC<IProps> = ({
  connectorUpdate,
  onSubmit,
  onClose,
  isLoading,
}) => {
  const [form] = Form.useForm();

  const { t } = useTranslation();

  return (
    <>
      <Form
        form={form}
        preserve={false}
        layout={'vertical'}
        initialValues={{
          name: connectorUpdate.name,
          maxPower: connectorUpdate.maxPower ?? 0,
          maxVoltage: connectorUpdate.maxVoltage ?? 0,
          maxAmperage: connectorUpdate.maxAmperage ?? 0,
          type: connectorUpdate.type || 'none',
          powerType: connectorUpdate.powerType || 'ac-1',
          format: connectorUpdate.format || 'c',
          startChargingPluggedEv:
            connectorUpdate.startChargingPluggedEv || false,
          hidden: connectorUpdate.hidden || false,
          qrCode: connectorUpdate.qrCode || '',
        }}
        onFinish={(values) =>
          onSubmit({ ...values, name: connectorUpdate.name })
        }
      >
        <Row gutter={16}>
          <Col span={8}>
            <Form.Item name="type" label={`${t('chargepoints.labels.type')}:`}>
              <Select
                showSearch
                value={connectorUpdate.type}
                filterOption={(input, option) =>
                  (option?.label ?? '').includes(input)
                }
                filterSort={(optionA, optionB) =>
                  optionA.label
                    .toLowerCase()
                    .localeCompare((optionB?.label ?? '').toLowerCase())
                }
                optionFilterProp="children"
                options={getConnectorsSelectOptions()}
              />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              name="powerType"
              label={`${t('chargepoints.labels.power-type')}:`}
            >
              <Select
                showSearch
                value={connectorUpdate.powerType}
                options={getPowerTypeSelectOptions()}
              />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              name="format"
              label={`${t('chargepoints.labels.format')}:`}
            >
              <Radio.Group className={s.radioGroup}>
                <Radio.Button className={s.radioButton} value="c">
                  {t('chargepoints.text.cable')}
                </Radio.Button>
                <Radio.Button className={s.radioButton} value="s">
                  {t('chargepoints.text.socket')}
                </Radio.Button>
              </Radio.Group>
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={16}>
          <Col span={8}>
            <Form.Item
              name="maxVoltage"
              label={`${t('chargepoints.labels.max-voltage')}:`}
            >
              <Input type="number" min={0} max={9999} />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              name="maxPower"
              label={`${t('chargepoints.labels.max-power')}:`}
            >
              <Input type="number" min={0} max={9999} />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              name="maxAmperage"
              label={`${t('chargepoints.labels.max-amperage')}:`}
            >
              <Input type="number" min={0} max={9999} />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={16}>
          <Col span={8}>
            <Form.Item
              name="qrCode"
              label={`${t('chargepoints.labels.qr-code')}: `}
            >
              <Input />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={8}>
            <Form.Item name="startChargingPluggedEv" valuePropName="checked">
              <Checkbox>
                {`${t('chargepoints.labels.start-charging-plugged-ev')}`}
              </Checkbox>
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item name="hidden" valuePropName="checked">
              <Checkbox>
                {`${t('chargepoints.labels.hide-connector')}`}
              </Checkbox>
            </Form.Item>
          </Col>
        </Row>

        <ModalFooter>
          <Button onClick={onClose}>{t('common.actions.cancel')}</Button>
          <Button type="primary" htmlType="submit" loading={isLoading}>
            {t('common.actions.save')}
          </Button>
        </ModalFooter>
      </Form>
    </>
  );
};

export default ConnectorEditForm;
