import { Col, Row } from 'antd';

import type { PricesRowProps } from '../types';

export const PricesRow = ({ leftInput, rightInput }: PricesRowProps) => {
  return (
    <Row gutter={[32, 16]} className="two-cols-row">
      <Col lg={16}>{leftInput}</Col>
      <Col lg={8}>{rightInput}</Col>
    </Row>
  );
};
