import { message } from 'antd';
import { MessageType } from 'antd/es/message/interface';

type MessageTypeKey = 'success' | 'error' | 'info' | 'warning' | 'loading';

export const showToastMessage = (
  type: MessageTypeKey,
  content: string,
  duration: number = 3,
): MessageType => {
  const messageFunction = message[type];
  if (messageFunction) {
    return messageFunction(content, duration);
  }
  throw new Error(`Unsupported message type: ${type}`);
};
