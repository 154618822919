import * as Services from 'core/services';
import * as Types from 'core/types';
import { AuthHelpers } from 'features/auth';

import {
  MutateTariffGroupData,
  TariffGroup,
  TariffGroupParams,
} from '../types';

export async function fetchPartnerTariffGroupUpdate(
  params: TariffGroupParams,
  data: MutateTariffGroupData,
) {
  try {
    const header = await AuthHelpers.authHeader();
    const response = await Services.apiService.put<TariffGroup>(
      `/partners/${params.partnerId}/tariffs/groups/${params.groupId}`,
      data,
      { headers: { ...header } },
    );
    return response.data;
  } catch (error: any) {
    if (!error.response) {
      throw error;
    }
    return Promise.reject(error.response.data as Types.HttpError);
  }
}
