import { omitBy } from 'lodash-es';
import dayjs from 'dayjs';
import * as Services from 'core/services';
import * as Types from 'core/types';
import { AuthHelpers } from 'features/auth';

import { Tariff, TariffUpdateActiveData, TariffParams } from '../types';

export async function fetchPartnerActiveTariffGroupUpdate(
  params: TariffParams,
  data: TariffUpdateActiveData,
) {
  try {
    const header = await AuthHelpers.authHeader();
    const dataWithoutFalsyValues = omitBy(data, (value) => !value);

    // remove validTo and validFrom if both values dates are in the past
    if (dayjs().isAfter(dayjs(data.validTo))) {
      delete dataWithoutFalsyValues.validFrom;
      delete dataWithoutFalsyValues.validTo;
    }

    const response = await Services.apiService.put<Tariff>(
      `/partners/${params.partnerId}/tariffs/groups/${params.groupId}/tariffs/${params.tariffId}/active`,
      {
        ...dataWithoutFalsyValues,
        validityUnrestricted: !data.validTo,
      },
      { headers: { ...header } },
    );
    return response.data;
  } catch (error: any) {
    if (!error.response) {
      throw error;
    }
    return Promise.reject(error.response.data as Types.HttpError);
  }
}
