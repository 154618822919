import * as Types from 'core/types';
import { useMutation, UseMutationOptions } from '@tanstack/react-query';

import { fetchPartnerTariffGroupTariffUpdate } from '../fetches';
import { Tariff } from '../types';

export function usePartnerTariffGroupTariffUpdateMutation(
  options?: UseMutationOptions<
    Tariff,
    Types.HttpError,
    Parameters<typeof fetchPartnerTariffGroupTariffUpdate>
  >,
) {
  return useMutation((args) => fetchPartnerTariffGroupTariffUpdate(...args), {
    retry: 0,
    ...options,
  });
}
