import { useEffect, useRef, useState } from 'react';
import { YMaps, Map, Placemark, ZoomControl } from '@pbe/react-yandex-maps';
import type ymaps from 'yandex-maps';
import { YMapsApi } from '@pbe/react-yandex-maps/typings/util/typing';

import { MapProps } from './types';

export const YandexMap = ({
  markerCoordinates,
  initialCoordinates,
  onChangeCoordinates,
  onReady,
}: MapProps) => {
  const mapRef = useRef<ymaps.Map | undefined>();
  const mapApiRef = useRef<YMapsApi | undefined>();

  useEffect(() => {
    if (mapRef.current) {
      mapRef.current.setCenter([markerCoordinates.lat, markerCoordinates.lng]);
    }
  }, [markerCoordinates]);

  return (
    <YMaps
      query={{
        lang: 'ru_RU',
        apikey: process.env.REACT_APP_YANDEX_MAPS_KEY,
        suggest_apikey: process.env.REACT_APP_YANDEX_SUGGEST_KEY,
      }}
    >
      <Map
        width="100%"
        height="400px"
        modules={['geocode', 'GeocodeResult', 'suggest']}
        defaultState={{
          center: [initialCoordinates.lat, initialCoordinates.lng],
          zoom: 12,
        }}
        options={{
          suppressMapOpenBlock: true,
        }}
        onClick={async (event: ymaps.IEvent) => {
          const coords = event.get('coords');
          onChangeCoordinates({ lat: coords[0], lng: coords[1] });
        }}
        onLoad={(api: YMapsApi) => {
          mapApiRef.current = api;
          onReady?.(api);
        }}
        instanceRef={mapRef}
      >
        <ZoomControl />
        <Placemark geometry={[markerCoordinates.lat, markerCoordinates.lng]} />
      </Map>
    </YMaps>
  );
};
