import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export type TransactionsState = {
  currency: string;
};

const initialState: TransactionsState = {
  currency: '',
};

export const transactionsSlice = createSlice({
  name: 'transactions',
  initialState,
  reducers: {
    setCurrency(state, action: PayloadAction<string>) {
      state.currency = action.payload;
    },
  },
});

export const { setCurrency } = transactionsSlice.actions;
export default transactionsSlice.reducer;
