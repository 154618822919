import { FC, memo } from 'react';
import { Table } from 'antd';
import type { DragEndEvent } from '@dnd-kit/core';
import {
  DndContext,
  PointerSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import { restrictToVerticalAxis } from '@dnd-kit/modifiers';
import {
  arrayMove,
  SortableContext,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';

import { useTariffDetailsContext } from '../context/TariffDetailsContext';

import { ElementsListProps, RowProps } from './types';
import { ElementsRow } from './ElementsRow';
import { useElementsListColumns } from './useElementsListColumns';

const ElementsList: FC<ElementsListProps> = ({
  list,
  updateList,
  onElementClick,
  onDelete,
}) => {
  const { tariffCurrency } = useTariffDetailsContext();
  const { isActiveTariff, isArchivedTariff } = useTariffDetailsContext();

  const isReadOnly = isActiveTariff || isArchivedTariff;

  const isSortingDisabled = isReadOnly || list.length < 2;

  const elementsListColumns = useElementsListColumns({
    tariffCurrency,
    onDelete,
    isReadOnly,
    isSortingDisabled,
  });

  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        distance: 3,
      },
    }),
  );

  const onDragEnd = ({ active, over }: DragEndEvent) => {
    if (active.id !== over?.id) {
      updateList((prev) => {
        const activeIndex = prev.findIndex((i) => i.id === active.id);
        const overIndex = prev.findIndex((i) => i.id === over?.id);
        return arrayMove(prev, activeIndex, overIndex);
      });
    }
  };

  return (
    <DndContext
      sensors={sensors}
      modifiers={[restrictToVerticalAxis]}
      onDragEnd={onDragEnd}
    >
      <SortableContext
        items={list.map((i) => i.id)}
        strategy={verticalListSortingStrategy}
        disabled={isSortingDisabled}
      >
        <Table
          onRow={(element) => ({ onClick: () => onElementClick(element) })}
          size="small"
          columns={elementsListColumns}
          dataSource={list.map((item) => ({
            key: item.id,
            ...item,
          }))}
          pagination={false}
          components={{
            body: {
              row: (props: Omit<RowProps, 'isSortingDisabled'>) => (
                <ElementsRow isSortingDisabled={isSortingDisabled} {...props} />
              ),
            },
          }}
        />
      </SortableContext>
    </DndContext>
  );
};

export default memo(ElementsList);
