import { FC, memo } from 'react';
import { useTranslation } from 'react-i18next';
import { Table, Typography, Button, Modal } from 'antd';
import { Link } from 'react-router-dom';
import { TariffGroup } from 'features/partners-tariffs-groups/types';
import { ColumnsType } from 'antd/es/table';

type Props = {
  tariffGroups: TariffGroup[];
  onDelete: (groupId: string) => void;
};

const TariffGroupsList: FC<Props> = ({ tariffGroups, onDelete }) => {
  const { t } = useTranslation();

  const handleDelete = ({ name, id }: TariffGroup) => {
    Modal.confirm({
      title: t('pricing.delete_group_modal.title', { groupName: name }),
      okText: t('common.actions.delete'),
      cancelText: t('common.actions.cancel'),
      type: 'confirm',
      onOk: () => onDelete(id),
    });
  };

  const columns: ColumnsType<TariffGroup & { key: string }> = [
    {
      dataIndex: 'name',
      key: 'name',
      render: (name: string, item: TariffGroup) => (
        <Link to={`group/${item?.id}`}>
          <Typography.Title level={5} style={{ margin: 0 }}>
            {item?.name}
          </Typography.Title>
        </Link>
      ),
    },
    {
      render: (_, item: TariffGroup) => (
        <Button hidden onClick={() => handleDelete(item)}>
          {t('common.actions.delete')}
        </Button>
      ),
    },
  ];

  return (
    <Table
      size="small"
      columns={columns}
      dataSource={tariffGroups.map((item) => ({
        key: item.id,
        ...item,
      }))}
      scroll={{ x: 500 }}
      pagination={false}
    />
  );
};

export default memo(TariffGroupsList);
