import { useTranslation } from 'react-i18next';
import { TransactionInvoicing } from 'features/transactions/types';
import { ColumnsType } from 'antd/es/table';

export const useInvoicingColumns = (): ColumnsType<
  Omit<TransactionInvoicing, 'invoiceId'>
> => {
  const { t } = useTranslation();
  return [
    {
      title: t('transactions.columns.invoice-number'),
      key: 'invoiceNo',
      dataIndex: 'invoiceNo',
    },
    {
      title: t('transactions.columns.currency'),
      key: 'currency',
      dataIndex: 'currency',
    },
    {
      title: t('transactions.columns.invoice-amount'),
      key: 'invAmount',
      dataIndex: 'invAmount',
    },
    {
      title: t('transactions.columns.invoice-amount-eur'),
      key: 'invAmountEur',
      dataIndex: 'invAmountEur',
    },
    {
      title: t('transactions.columns.discount'),
      key: 'discount',
      dataIndex: 'discount',
    },
    {
      title: t('transactions.columns.discount-eur'),
      key: 'discountEur',
      dataIndex: 'discountEur',
    },
  ];
};
