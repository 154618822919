import { useTranslation } from 'react-i18next';

export const useTarificationColumns = () => {
  const { t } = useTranslation();
  return [
    {
      title: t('transactions.columns.currency'),
      dataIndex: 'currency',
      key: 'currency',
    },
    {
      title: t('transactions.columns.amount'),
      dataIndex: 'amount',
      key: 'amount',
    },
    {
      title: t('transactions.columns.vat-amount'),
      dataIndex: 'vatAmount',
      key: 'vatAmount',
    },
  ];
};
