import { YMapsApi } from '@pbe/react-yandex-maps/typings/util/typing';

const MAP_LOAD_ERROR = 'Maps API is not initialized';

export const getSuggestions = async (query: string, mapsApi: YMapsApi) => {
  const suggestions = await mapsApi.suggest(query);
  return suggestions;
};

export const getSuggestionsOptions = async (
  query: string,
  mapsApi?: YMapsApi,
) => {
  if (!mapsApi) {
    throw new Error(MAP_LOAD_ERROR);
  }

  const suggestions = await getSuggestions(query, mapsApi);
  return suggestions.map((suggestion) => ({
    value: suggestion.value,
    label: suggestion.displayName,
  }));
};

export const getCoordinatesByAddress = async (
  address: string,
  mapsApi?: YMapsApi,
) => {
  if (!mapsApi) {
    throw new Error(MAP_LOAD_ERROR);
  }

  const result = await mapsApi.geocode(address);
  const firstGeoObject = result.geoObjects.get(0);
  const geometry = firstGeoObject?.geometry as ymaps.IPointGeometry;

  const coords = geometry?.getCoordinates() ?? [];
  return coords;
};

export const getAddressByCoordinates = async (
  coords: readonly [number, number],
  mapsApi?: YMapsApi,
) => {
  if (!mapsApi) {
    throw new Error(MAP_LOAD_ERROR);
  }

  const result = await mapsApi.geocode(coords);
  const firstGeoObject = result.geoObjects.get(0) as ymaps.GeocodeResult;

  const address = firstGeoObject?.getAddressLine();
  return address;
};
