import { Col, Row } from 'antd';

import type { RowWithLabelProps } from '../types';

export const RowWithLabel = ({ label, children }: RowWithLabelProps) => {
  return (
    <Row gutter={[0, 4]} className="row-with-label">
      <Col lg={24}>{label}</Col>
      <Col lg={24}>{children}</Col>
    </Row>
  );
};
