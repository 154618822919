import { FC } from 'react';
import cn from 'classnames';
import { useParams, Link, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useErrorTranslation } from 'i18n/useErrorTranslation';
import {
  usePartnerTariffGroupQuery,
  usePartnerTariffGroupUpdateMutation,
  usePartnerTariffGroupCreateMutation,
  usePartnerTariffGroupTariffsQuery,
  usePartnerTariffGroupTariffActivateMutation,
  usePartnerTariffGroupTariffArchiveMutation,
  usePartnerTariffCopyMutation,
} from 'features/partners-tariffs-groups/queries';
import { usePartnerId } from 'core/providers/PartnerProvider';
import { TariffGroupForm } from 'pages/Pricing/TariffGroupDetails/TariffGroupForm';
import { Spin, Button, Form, Typography, Modal, Space } from 'antd';
import { TariffsList } from 'pages/Pricing/TariffGroupDetails/TariffsList';
import { Padding } from 'components/Padding';
import { Tariff } from 'features/partners-tariffs-groups/types';
import { usePartnerTariffGroupTariffDeleteMutation } from 'features/partners-tariffs-groups/queries/usePartnerTariffGroupTariffDeleteMutation';
import {
  PlusOutlined,
  CheckCircleFilled,
  ExclamationCircleFilled,
} from '@ant-design/icons';

import s from './TariffGroupDetails.module.css';

type Props = {
  className?: string;
};

const { Title, Text } = Typography;

const TariffGroupDetails: FC<Props> = ({ className }) => {
  const partnerId = usePartnerId();
  const { groupId } = useParams();
  const navigate = useNavigate();

  const isLoadData = !!groupId && groupId !== 'new';

  const { t } = useTranslation();
  const { t: errorT } = useErrorTranslation();

  const {
    data: list,
    isLoading: isListLoading,
    refetch: refetchList,
  } = usePartnerTariffGroupTariffsQuery(
    {
      partnerId,
      groupId: groupId || '',
    },
    {
      enabled: isLoadData,
    },
  );

  const updateMutation = usePartnerTariffGroupUpdateMutation();
  const copyMutation = usePartnerTariffCopyMutation();
  const createMutation = usePartnerTariffGroupCreateMutation();
  const activateMutation = usePartnerTariffGroupTariffActivateMutation();
  const archiveMutation = usePartnerTariffGroupTariffArchiveMutation();
  const deleteMutation = usePartnerTariffGroupTariffDeleteMutation();

  const { data: groupData, isLoading: isFormLoading } =
    usePartnerTariffGroupQuery(
      { partnerId, groupId: groupId || '' },
      { enabled: isLoadData },
    );
  const [form] = Form.useForm();

  if (!partnerId || !groupId) {
    return null;
  }

  const handleSubmit = (values: { name: string }) => {
    if (isLoadData) {
      updateMutation.mutate([{ partnerId, groupId }, { name: values.name }]);
    } else {
      createMutation
        .mutateAsync([{ partnerId }, { name: values.name }])
        .then((newGroup) => {
          navigate(`/pricing/group/${newGroup.id}`);
        });
    }
  };

  const handleActivate = (tariff: Tariff) => {
    Modal.confirm({
      title: t('pricing.activate_modal.title', {
        tariffId: tariff.name || tariff.id,
      }),
      content: t('pricing.activate_modal.content'),
      okText: t('pricing.activate_modal.submit'),
      cancelText: t('common.actions.cancel'),
      type: 'confirm',
      onOk() {
        activateMutation.mutate([{ partnerId, groupId, tariffId: tariff.id }], {
          onSuccess: () => refetchList(),
        });
      },
      closable: true,
      autoFocusButton: 'cancel',
    });
  };

  const handleArchive = (tariff: Tariff) => {
    Modal.confirm({
      title: t('pricing.archive_modal.title', {
        tariffId: tariff.name || tariff.id,
      }),
      content: t('pricing.archive_modal.content'),
      okText: t('pricing.archive_modal.submit'),
      cancelText: t('common.actions.cancel'),
      type: 'confirm',
      onOk() {
        archiveMutation.mutate([{ partnerId, groupId, tariffId: tariff.id }], {
          onSuccess: () => refetchList(),
        });
      },
      closable: true,
      autoFocusButton: 'cancel',
    });
  };

  const handleDelete = (tariff: Tariff) => {
    Modal.confirm({
      title: t('pricing.delete_modal.title', {
        tariffId: tariff.name || tariff.id,
      }),
      okText: t('common.actions.delete'),
      cancelText: t('common.actions.cancel'),
      type: 'confirm',
      onOk() {
        deleteMutation.mutate([{ partnerId, groupId, tariffId: tariff.id }], {
          onSuccess: () => refetchList(),
        });
      },
      closable: true,
      autoFocusButton: 'cancel',
    });
  };

  const handleCopy = (tariffId: Tariff['id']) => {
    copyMutation.mutate([{ partnerId, tariffId }], {
      onSuccess: () => refetchList(),
    });
  };

  return (
    <div className={cn(s.wrapper, className)}>
      {isLoadData && (isListLoading || isFormLoading) ? (
        <Spin />
      ) : (
        <>
          <Padding>
            <TariffGroupForm
              form={form}
              data={groupData}
              onSubmit={handleSubmit}
            />

            <div className={cn(s.box, s.spaceBetween)}>
              <Title level={5}>{t('pricing.titles.tariffs')}</Title>
              <Button>
                <Link to={`tariff/new`}>
                  <PlusOutlined /> {t('pricing.actions.add-tariff')}
                </Link>
              </Button>
            </div>

            <TariffsList
              list={list || []}
              onActivate={handleActivate}
              onArchive={handleArchive}
              onDelete={handleDelete}
              onCopy={handleCopy}
            />

            <Space className={s.box}>
              <Button
                type={'primary'}
                onClick={() => form.submit()}
                loading={updateMutation.isLoading || createMutation.isLoading}
              >
                {t('common.actions.save')}
              </Button>
              {(createMutation.isSuccess || updateMutation.isSuccess) && (
                <Text type={'success'}>
                  <CheckCircleFilled /> {t('pricing.labels.group-saved')}
                </Text>
              )}
              {createMutation.isError && (
                <Text type={'danger'}>
                  <ExclamationCircleFilled />{' '}
                  {errorT(
                    createMutation.error?.translationKey,
                    createMutation.error.message,
                  )}
                </Text>
              )}
              {updateMutation.isError && (
                <Text type={'danger'}>
                  <ExclamationCircleFilled />{' '}
                  {errorT(
                    updateMutation.error?.translationKey,
                    updateMutation.error.message,
                  )}
                </Text>
              )}
            </Space>
          </Padding>
        </>
      )}
    </div>
  );
};

export default TariffGroupDetails;
