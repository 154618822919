import { Form, InputNumber } from 'antd';
import { useTranslation } from 'react-i18next';
import { maxTariffPrice } from 'core/utils/tariffUtils';

import { useTariffDetailsContext } from '../../context/TariffDetailsContext';

import { PricesRow } from './PricesRow';

export const FlatPriceRow = () => {
  const { t } = useTranslation();

  const { tariffCurrency } = useTariffDetailsContext();

  return (
    <PricesRow
      leftInput={
        <Form.Item
          name={['tariffPrices', 'flat', 'price']}
          label={`${t('pricing.labels.flat')}:`}
          className="inline-form-item"
        >
          <InputNumber
            placeholder={t('pricing.labels.price')}
            min={0}
            max={maxTariffPrice}
            type={'number'}
            addonAfter={`${tariffCurrency}`}
          />
        </Form.Item>
      }
      rightInput={
        <Form.Item
          name={['tariffPrices', 'flat', 'vatPercent']}
          label={`${t('pricing.labels.vat')}:`}
          className="inline-form-item"
        >
          <InputNumber min={0} max={100} addonAfter={'%'} />
        </Form.Item>
      }
    />
  );
};
