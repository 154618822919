import { useTranslation } from 'react-i18next';

export const useTarificationDetailsColumns = () => {
  const { t } = useTranslation();
  
  return [
    {
      title: t('transactions.columns.group'),
      key: 'group',
      dataIndex: 'group',
    },
    {
      title: t('transactions.columns.amount'),
      key: 'amount',
      dataIndex: 'amount',
      render: (amount: number) => amount || 0,
    },
    {
      title: t('transactions.columns.vat-amount'),
      key: 'vatAmount',
      dataIndex: 'vatAmount',
      render: (amount: number) => amount || 0,
    },
  ];
};
