import { useMemo } from 'react';
import { Descriptions, Table } from 'antd';
import { useTranslation } from 'react-i18next';
import type {
  TransactionTariff,
  TransactionTarificationGroups,
} from 'features/transactions/types';
import type { WebTranslateKey } from 'i18n/types';

import { useTarificationDetailsColumns } from './useTarificationDetailsColumns';

const transactionTarificationGroups: Record<
  TransactionTarificationGroups,
  WebTranslateKey
> = {
  energy: 'transactions.tarification-group.energy',
  flat: 'transactions.tarification-group.flat',
  time: 'transactions.tarification-group.time',
  parking: 'transactions.tarification-group.parking',
};

export const TarifficationDetails = ({
  tariff,
}: {
  tariff: TransactionTariff;
}) => {
  const { t } = useTranslation();

  const tarificationDetailsColumns = useTarificationDetailsColumns();

  const tarificationDetailsData = useMemo(() => {
    return Object.entries(transactionTarificationGroups)
      .map(([group, translationKey]) => {
        if (!tariff?.totalGrouped) {
          return {
            group: t(translationKey),
            amount: 0,
            vatAmount: 0,
          };
        }

        return {
          group: t(translationKey),
          amount: tariff?.totalGrouped[group]?.amount || 0,
          vatAmount: tariff?.totalGrouped[group]?.vatAmount || 0,
        };
      })
      .filter(({ amount }) => amount > 0);
  }, [tariff?.totalGrouped, t]);

  return (
    <>
      <Descriptions title={t('transactions.titles.tariffication-details')} />
      <Table
        size={'small'}
        pagination={false}
        scroll={{ x: 'max-content' }}
        dataSource={tarificationDetailsData}
        columns={tarificationDetailsColumns}
      />
    </>
  );
};
