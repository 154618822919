import { Col, Row, Space } from 'antd';

import { RangeInputsRowProps } from '../types';

export const RangeInputsRow = ({
  label,
  children,
  rowGutter = [8, 4],
}: RangeInputsRowProps) => {
  return (
    <Row gutter={rowGutter} align={'middle'} className="range-inputs-row">
      <Col lg={24}>{label}:</Col>
      <Col lg={24}>
        <Space.Compact>{children}</Space.Compact>
      </Col>
    </Row>
  );
};
