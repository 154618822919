import { Button, Spin, Typography } from 'antd';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Padding } from 'components/Padding';
import { Link } from 'react-router-dom';
import { useIsSubpartner } from 'core/providers/PartnerProvider';
import { usePartnerTariffGroupQuery } from 'features/partners-tariffs-groups/queries';

import { ChargePointTariffUpdateForm } from '../Forms/ChargePointTariffUpdateForm';
import { useChargePointsDetailsContext } from '../ChargePointsDetailsContext';

export function ChargePointTariff() {
  const [isOpenUpdate, setIsOpenUpdate] = useState(false);
  const { chargePoint, isExternalResource } = useChargePointsDetailsContext();
  const isSubpartner = useIsSubpartner();

  const { partner, tariffGroupId } = chargePoint;

  const { data: tariffGroup, isInitialLoading } = usePartnerTariffGroupQuery(
    {
      partnerId: partner.id,
      groupId: tariffGroupId,
    },
    {
      enabled: !!partner.id && !!tariffGroupId && !isSubpartner,
    },
  );

  const { t } = useTranslation();

  if (isInitialLoading) {
    return <Spin />;
  }

  return (
    <div>
      <Padding isHorizontal>
        <Typography.Title level={4} style={{ margin: 0 }}>
          <Link
            style={{ color: 'rgba(0, 0, 0, 0.88)' }}
            to={`/pricing/group/${tariffGroup?.id}`}
          >
            {tariffGroup?.name ?? '...'}
          </Link>
        </Typography.Title>
        <Button
          type={'primary'}
          disabled={isExternalResource || isSubpartner}
          onClick={() => setIsOpenUpdate(true)}
        >
          {t('common.actions.update')}
        </Button>
      </Padding>
      {!isSubpartner && (
        <ChargePointTariffUpdateForm
          isOpen={isOpenUpdate}
          chargePoint={chargePoint}
          onCancel={() => setIsOpenUpdate(false)}
          onSuccess={() => setIsOpenUpdate(false)}
        />
      )}
    </div>
  );
}
