import { Tariff } from 'features/partners-tariffs-groups/types';
import { WebTranslateKey } from 'i18n/types';

export type TariffStatusType = 'draft' | 'active' | 'archived';

export const maxTariffPrice = 999999999;

export const tariffStatusColors: Record<TariffStatusType, string> = {
  draft: 'green',
  active: 'cyan',
  archived: 'gold',
};

export enum TariffStatus {
  active = 'active',
  draft = 'draft',
  archived = 'archived',
}

export const getTariffStatus = (tariff: Tariff): TariffStatusType =>
  tariff.archivedAt ? 'archived' : tariff.activatedAt ? 'active' : 'draft';

export const tariffStatusLabels: Record<TariffStatus, WebTranslateKey> = {
  [TariffStatus.active]: 'pricing.tariff-status.active',
  [TariffStatus.draft]: 'pricing.tariff-status.draft',
  [TariffStatus.archived]: 'pricing.tariff-status.archived',
};
