import * as Types from 'core/types';
import { useQuery, UseQueryOptions } from '@tanstack/react-query';

import { fetchPartnerTariffGroups } from '../fetches';
import { FetchTariffGroupsParams, TariffGroupsResponse } from '../types';

export const USE_PARTNER_TARIFF_GROUPS_QUERY_KEY = 'partners.tariffs.groups';

export function usePartnerTariffGroupsQuery(
  params: FetchTariffGroupsParams,
  options?: UseQueryOptions<
    TariffGroupsResponse,
    Types.HttpError,
    TariffGroupsResponse,
    any[]
  >,
) {
  return useQuery(
    [USE_PARTNER_TARIFF_GROUPS_QUERY_KEY, params],
    () => fetchPartnerTariffGroups(params),
    {
      refetchOnWindowFocus: false,
      refetchInterval: false,
      refetchOnMount: true,
      refetchOnReconnect: false,
      refetchIntervalInBackground: false,
      ...options,
    },
  );
}
