import { useMemo } from 'react';
import { Descriptions, Table } from 'antd';
import { useTranslation } from 'react-i18next';
import type { TransactionInvoicing as Invoicing } from 'features/transactions/types';

import { useInvoicingColumns } from './useInvoicingColumns';

export const TransactionInvoicing = ({
  invoicing,
}: {
  invoicing: Invoicing;
}) => {
  const { t } = useTranslation();

  const invoicingColumns = useInvoicingColumns();

  const invoicingData: Omit<Invoicing, 'invoiceId'>[] = useMemo(() => {
    return [
      {
        invoiceNo: invoicing?.invoiceNo ?? '',
        currency: invoicing?.currency ?? '',
        invAmount: invoicing?.invAmount ?? 0,
        invAmountEur: invoicing?.invAmountEur ?? 0,
        discount: invoicing?.discount ?? 0,
        discountEur: invoicing?.discountEur ?? 0,
      },
    ];
  }, [invoicing]);

  return (
    <>
      <Descriptions title={t('transactions.titles.invoicing')} />
      <Table
        size={'small'}
        pagination={false}
        scroll={{ x: 'max-content' }}
        dataSource={invoicingData}
        columns={invoicingColumns}
      />
    </>
  );
};
