import { useQueryClient } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import { useErrorTranslation } from 'i18n/useErrorTranslation';
import { Alert, Button, Form, Modal, Select, Spin } from 'antd';
import {
  useChargePointsUpdateMutation,
  USE_CHARGE_POINT_QUERY_KEY,
} from 'features/charge-points/queries';
import { ChargePointPartnerItem } from 'features/charge-points/types/ChargePointPartnerItem';
import { usePartnerTariffGroupsQuery } from 'features/partners-tariffs-groups/queries';

export interface ChargePointTariffUpdateFormProps {
  isOpen?: boolean;
  chargePoint: ChargePointPartnerItem;
  onCancel: () => void;
  onSuccess: () => void;
}

export function ChargePointTariffUpdateForm({
  isOpen,
  chargePoint,
  onCancel,
  onSuccess,
}: ChargePointTariffUpdateFormProps) {
  const [form] = Form.useForm();
  const queryClient = useQueryClient();

  const update = useChargePointsUpdateMutation();

  const { t } = useTranslation();
  const { t: errorT } = useErrorTranslation();

  const tariffsQuery = usePartnerTariffGroupsQuery(
    {
      partnerId: chargePoint?.partner?.id,
    },
    {
      enabled: !!chargePoint?.partner?.id,
    },
  );

  const handleUpdate = (values: any) => {
    update.mutate(
      {
        id: chargePoint?.id,
        tariffGroupId: values.groupId,
        deviceId: chargePoint?.device?.deviceId,
        partnerId: chargePoint?.partner?.id,
      },
      {
        onSuccess: () => {
          queryClient.invalidateQueries([
            USE_CHARGE_POINT_QUERY_KEY,
            chargePoint?.id,
          ]);
          onSuccess();
        },
      },
    );
  };
  return (
    <Modal
      open={isOpen}
      onCancel={onCancel}
      footer={[
        <Button key={'cancel'} type={'default'} onClick={onCancel}>
          {t('common.actions.cancel')}
        </Button>,
        <Button
          key={'save'}
          type={'primary'}
          htmlType={'submit'}
          form={'tariff-form'}
          loading={tariffsQuery.isLoading || update.isLoading}
        >
          {t('common.actions.save')}
        </Button>,
      ]}
    >
      <Form
        id={'tariff-form'}
        name="basic"
        form={form}
        layout="vertical"
        style={{ widows: '100%' }}
        initialValues={{ groupId: chargePoint?.tariffGroupId }}
        onFinish={handleUpdate}
        autoComplete="off"
      >
        <Form.Item
          label={`${t('chargepoints.labels.tariff')}:`}
          name="groupId"
          rules={[
            {
              required: true,
              message: t('chargepoints.rules.choose-tariff-group'),
            },
          ]}
        >
          <Select
            placeholder={t('chargepoints.placeholders.select-option')}
            style={{ widows: '100%' }}
            allowClear
          >
            {tariffsQuery.data?.map((item) => (
              <Select.Option key={item.id} value={item.id}>
                {item.name}{' '}
                {item.isDefault ? `(${t('chargepoints.text.default')})` : null}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
      </Form>
      {tariffsQuery.isLoading && (
        <div style={{ padding: 20, textAlign: 'center' }}>
          <Spin />
        </div>
      )}
      {!!update.error && (
        <Form.Item>
          <Alert
            type={'error'}
            description={errorT(
              update.error?.translationKey,
              update.error?.message,
            )}
          />
        </Form.Item>
      )}
    </Modal>
  );
}
