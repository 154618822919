import { secsToTime } from 'core/utils';
import dayjs from 'dayjs';
import { TransactionCalculationsItem } from 'features/transactions/types/TransactionCalculations';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from 'store';
import type { ColumnsType } from 'antd/es/table';

import type { TransactionColumns } from './types';

export const useTransactionCalculationsColumns =
  (): ColumnsType<TransactionCalculationsItem> => {
    const { t } = useTranslation();
    const { currency } = useAppSelector((state) => state.transactions);

    const getTranslatedTitleWithCurrency = (key: TransactionColumns) => {
      return `${t(`transactions.columns.${key}`)} (${currency})`;
    };

    return [
      {
        title: t('transactions.columns.delta-duration-sec'),
        dataIndex: 'deltaDurationSec',
        key: 'deltaDurationSec',
        render: (secs: number) => secsToTime(secs),
      },
      {
        title: t('transactions.columns.delta-energy-kwh'),
        dataIndex: 'deltaEnergyKwh',
        key: 'deltaEnergyKwh',
      },
      {
        title: getTranslatedTitleWithCurrency('energy-amount'),
        dataIndex: 'mvId',
        key: 'energyAmount',
        render: (_: string, record) => record.dimensions?.energy?.amount,
      },
      {
        title: getTranslatedTitleWithCurrency('energy-vat-amount'),
        dataIndex: 'mvId',
        key: 'energyVatAmount',
        render: (_: string, record) => record.dimensions?.energy?.vatAmount,
      },
      {
        title: getTranslatedTitleWithCurrency('flat-amount'),
        dataIndex: 'mvId',
        key: 'flatAmount',
        render: (_: string, record) => record.dimensions?.flat?.amount,
      },
      {
        title: getTranslatedTitleWithCurrency('flat-vat-amount'),
        dataIndex: 'mvId',
        key: 'flatVatAmount',
        render: (_: string, record) => record.dimensions?.flat?.vatAmount,
      },
      {
        title: getTranslatedTitleWithCurrency('time-amount'),
        dataIndex: 'mvId',
        key: 'timeAmount',
        render: (_: string, record) => record.dimensions?.time?.amount,
      },
      {
        title: getTranslatedTitleWithCurrency('time-vat-amount'),
        dataIndex: 'mvId',
        key: 'timeVatAmount',
        render: (_: string, record) => record.dimensions?.time?.vatAmount,
      },
      {
        title: t('transactions.columns.timestamp'),
        dataIndex: 'ts',
        key: 'ts',
        render: (ts: string) =>
          ts ? dayjs(ts).format('DD.MM.YYYY HH:mm') : '',
      },
    ];
  };
