import { Col, Form, InputNumber, Row } from 'antd';
import { useTranslation } from 'react-i18next';

import { RangeInputsRow } from './RangeInputsRow';

export const ElementConfigurationInputs = () => {
  const { t } = useTranslation();

  return (
    <>
      <Row gutter={[16, 16]}>
        <Col lg={12}>
          <RangeInputsRow label={t('pricing.labels.power')}>
            <>
              <Form.Item name={['restriction', 'minPower']}>
                <InputNumber
                  placeholder={t('pricing.labels.min')}
                  addonAfter={'-'}
                  min={0}
                  max={999999}
                />
              </Form.Item>
              <Form.Item name={['restriction', 'maxPower']}>
                <InputNumber
                  placeholder={t('pricing.labels.max')}
                  addonAfter={t('pricing.terms.kw')}
                  min={0}
                  max={999999}
                />
              </Form.Item>
            </>
          </RangeInputsRow>
        </Col>
        <Col lg={12}>
          <RangeInputsRow label={t('pricing.labels.energy')}>
            <>
              <Form.Item name={['restriction', 'minKwh']}>
                <InputNumber
                  placeholder={t('pricing.labels.min')}
                  addonAfter={'-'}
                  min={0}
                  max={999999}
                />
              </Form.Item>
              <Form.Item name={['restriction', 'maxKwh']}>
                <InputNumber
                  placeholder={t('pricing.labels.max')}
                  addonAfter={t('pricing.terms.kwh')}
                  min={0}
                  max={999999}
                />
              </Form.Item>
            </>
          </RangeInputsRow>
        </Col>
      </Row>
      <Row gutter={[16, 16]}>
        <Col lg={12}>
          <RangeInputsRow label={t('pricing.labels.current')}>
            <>
              <Form.Item name={['restriction', 'minCurrent']}>
                <InputNumber
                  placeholder={t('pricing.labels.min')}
                  addonAfter={'-'}
                  min={0}
                  max={999999}
                />
              </Form.Item>
              <Form.Item name={['restriction', 'maxCurrent']}>
                <InputNumber
                  placeholder={t('pricing.labels.max')}
                  addonAfter={'A'}
                  min={0}
                  max={999999}
                />
              </Form.Item>
            </>
          </RangeInputsRow>
        </Col>
        <Col lg={12}>
          <RangeInputsRow label={t('pricing.labels.duration')}>
            <>
              <Form.Item name={['restriction', 'minDuration']}>
                <InputNumber
                  placeholder={t('pricing.labels.min')}
                  addonAfter={'-'}
                  min={0}
                  max={999999}
                />
              </Form.Item>
              <Form.Item name={['restriction', 'maxDuration']}>
                <InputNumber
                  placeholder={t('pricing.labels.max')}
                  addonAfter={t('pricing.labels.minute')}
                  min={0}
                  max={999999}
                />
              </Form.Item>
            </>
          </RangeInputsRow>
        </Col>
      </Row>
    </>
  );
};
