import { Line } from '@ant-design/charts';
import { Descriptions } from 'antd';
import dayjs from 'dayjs';
import { ResTransactionMetrics } from 'features/transactions/types/responses';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

export const TransactionMetrics = ({
  metrics,
}: {
  metrics: ResTransactionMetrics;
}) => {
  const { t } = useTranslation();

  const transactionMetricsData = useMemo(() => {
    return (metrics?.items ?? [])
      .reduce((accum: Record<string, any>[], current) => {
        const accept = [];

        if (!!current.currentImport) {
          accept.push({
            value: current.currentImport,
            timestamp: current.timestamp,
            category: 'currentImport',
          });
        }

        if (!!current.powerActiveImport) {
          accept.push({
            value: current.powerActiveImport / 1000,
            timestamp: current.timestamp,
            category: 'powerActiveImport',
          });
        }

        if (!!current.soc) {
          accept.push({
            value: current.soc,
            timestamp: current.timestamp,
            category: 'soc',
          });
        }

        return [...accum, ...accept];
      }, [])
      .sort((a, b) => a.timestamp.localeCompare(b.timestamp));
  }, [metrics]);

  return (
    <>
      <Descriptions title={t('transactions.titles.metrics-charts')} />
      <Line
        data={transactionMetricsData}
        xField="timestamp"
        yField="value"
        seriesField="category"
        xAxis={{
          label: { formatter: (v) => dayjs(v).format('HH:mm') },
        }}
        height={400}
      />
    </>
  );
};
