import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import { ConnectorLabel } from 'components/view/ConnectorLabel';
import { ConnectorType } from 'features/charge-points/types';

export const useTransactionColumns = () => {
  const { t } = useTranslation();
  return [
    {
      title: t('transactions.columns.connector-id'),
      dataIndex: 'connectorId',
      key: 'connectorId',
    },
    {
      title: t('transactions.columns.connector-type'),
      dataIndex: 'connectorType',
      key: 'connectorType',
      render: (type: ConnectorType) => <ConnectorLabel type={type} />,
    },
    {
      title: t('transactions.columns.status'),
      dataIndex: 'status',
      key: 'status',
    },
    {
      title: t('transactions.columns.device-transaction-id'),
      dataIndex: 'deviceTransactionId',
      key: 'deviceTransactionId',
    },
    {
      title: t('transactions.columns.auth-status'),
      dataIndex: 'authStatus',
      key: 'authStatus',
    },
    {
      title: t('transactions.columns.tag'),
      dataIndex: 'tag',
      key: 'tag',
    },
    {
      title: t('transactions.columns.start-at'),
      dataIndex: 'startAt',
      key: 'startAt',
      render: (date: string) =>
        date ? dayjs(date).format('DD/MM/YYYY HH:mm') : '-',
    },
    {
      title: t('transactions.columns.stop-at'),
      dataIndex: 'stopAt',
      key: 'stopAt',
      render: (date: string) =>
        date ? dayjs(date).format('DD/MM/YYYY HH:mm') : '-',
    },
    {
      title: t('transactions.columns.energy-wh'),
      dataIndex: 'energyWh',
      key: 'energyWh',
    },
    {
      title: t('transactions.columns.started-by'),
      dataIndex: 'startedBy',
      key: 'startedBy',
    },
    {
      title: t('transactions.columns.stopped-by'),
      dataIndex: 'stoppedBy',
      key: 'stoppedBy',
    },
    {
      title: t('transactions.columns.meter-stop'),
      dataIndex: 'meterStop',
      key: 'meterStop',
    },
  ];
};
