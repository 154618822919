import { useMemo } from 'react';
import { Descriptions, Table } from 'antd';
import { useTranslation } from 'react-i18next';
import type { TransactionTariff } from 'features/transactions/types';

import { useTarificationColumns } from './useTarificationColumns';

export const TarificationInfo = ({ tariff }: { tariff: TransactionTariff }) => {
  const { t } = useTranslation();
  const tarificationColumns = useTarificationColumns();

  const tarificationInfoData = useMemo(() => {
    return [
      {
        currency: tariff?.currency ?? '',
        amount: tariff?.totalAmount?.amount ?? 0,
        vatAmount: tariff?.totalAmount?.vatAmount ?? 0,
      },
    ].filter(({ amount }) => amount > 0);
  }, [tariff]);

  return (
    <>
      <Descriptions title={t('transactions.titles.tariffication-info')} />
      <Table
        size={'small'}
        pagination={false}
        scroll={{ x: 'max-content' }}
        dataSource={tarificationInfoData}
        columns={tarificationColumns}
      />
    </>
  );
};
