import { DatePicker, Form } from 'antd';
import { useTranslation } from 'react-i18next';

import { validateTime } from '../utils';

import { RangeInputsRow } from './RangeInputsRow';

const { RangePicker } = DatePicker;

export const TimeRangeInput = () => {
  const { t } = useTranslation();

  return (
    <RangeInputsRow label={t('pricing.labels.time')}>
      <Form.Item name={['restriction', 'time', 'range']} rules={[validateTime]}>
        <RangePicker
          showTime={{ format: 'HH:mm' }}
          format="HH:mm"
          picker="time"
          style={{ maxWidth: 240 }}
        />
      </Form.Item>
    </RangeInputsRow>
  );
};
