import * as Types from 'core/types';
import { useQuery, UseQueryOptions } from '@tanstack/react-query';

import { fetchPartnerTariffGroup } from '../fetches';
import { TariffGroup } from '../types';

export const USE_PARTNER_TARIFF_GROUP_QUERY_KEY = 'partners.tariffs.group';

export function usePartnerTariffGroupQuery(
  params: Parameters<typeof fetchPartnerTariffGroup>[0],
  options?: UseQueryOptions<TariffGroup, Types.HttpError, TariffGroup, any>,
) {
  return useQuery(
    [USE_PARTNER_TARIFF_GROUP_QUERY_KEY, params],
    () => fetchPartnerTariffGroup(params),
    {
      refetchOnWindowFocus: false,
      refetchInterval: false,
      refetchOnMount: true,
      refetchOnReconnect: false,
      refetchIntervalInBackground: false,
      ...options,
    },
  );
}
