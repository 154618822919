import * as Types from 'core/types';
import { useMutation, UseMutationOptions } from '@tanstack/react-query';

import { fetchPartnerTariffGroupDelete } from '../fetches';

export function usePartnerTariffGroupDeleteMutation(
  options?: UseMutationOptions<
    unknown,
    Types.HttpError,
    Parameters<typeof fetchPartnerTariffGroupDelete>
  >,
) {
  return useMutation((args) => fetchPartnerTariffGroupDelete(...args), {
    retry: 0,
    ...options,
  });
}
