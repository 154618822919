import { Form, Select } from 'antd';
import { useTranslation } from 'react-i18next';
import { getWeekDayLabel, weekDays } from 'core/types/WeekDay';

import { RowWithLabel } from './RowWithLabel';

export const DaysSelector = () => {
  const { t } = useTranslation();

  return (
    <RowWithLabel label={`${t('pricing.labels.days')}:`}>
      <Form.Item name={['restriction', 'daysOfWeek']}>
        <Select
          mode="tags"
          allowClear
          maxTagCount={4}
          placeholder={t('pricing.placeholders.select-days')}
          options={weekDays.map((day) => ({
            label: getWeekDayLabel(day),
            value: day,
          }))}
        />
      </Form.Item>
    </RowWithLabel>
  );
};
