import { Card } from 'antd';
import { usePartnerId } from 'core/providers/PartnerProvider';
import { usePartnerHashtagsQuery } from 'features/hashtags/queries';

import { EnergyPriceRow } from './EnergyPriceRow';
import { TimePriceRow } from './TimePriceRow';
import { FlatPriceRow } from './FlatPriceRow';
import { ValidityPeriodRow } from './ValidityPeriodRow';
import { TariffConditionsTitle } from './TariffConditionsTitle';
import { ChargepointHashTagsSelector } from './TagHashTagsSelector';
import { TagHashTagsSelector } from './ChargepointHashTagsSelector';
import { ConnectorTypesSelector } from './ConnectorTypesSelector';
import { DaysSelector } from './DaysSelector';
import { ElementConfigurationInputs } from './ElementConfigurationInputs';
import { TimeRangeInput } from './TimeRangeInput';

export const AdvancedFormInputs = () => {
  const partnerId = usePartnerId();
  const hashtagsQuery = usePartnerHashtagsQuery([partnerId], {
    enabled: !!partnerId,
  });

  return (
    <>
      <Card className="main-section" bordered={false}>
        <EnergyPriceRow />
        <TimePriceRow />
        <FlatPriceRow />

        <ValidityPeriodRow />
      </Card>
      <ElementConfigurationInputs />

      <ChargepointHashTagsSelector hashtags={hashtagsQuery.data ?? []} />

      <TagHashTagsSelector hashtags={hashtagsQuery.data ?? []} />

      <ConnectorTypesSelector />

      <DaysSelector />
      <TimeRangeInput />
    </>
  );
};
