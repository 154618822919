import { Typography } from 'antd';
import { useTranslation } from 'react-i18next';

export const TariffConditionsTitle = () => {
  const { t } = useTranslation();

  return (
    <Typography.Title level={5}>
      {t('pricing.titles.tariff-conditions')}
    </Typography.Title>
  );
};
