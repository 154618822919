import * as Services from 'core/services';
import * as Types from 'core/types';
import { AuthHelpers } from 'features/auth';

import { Tariff, TariffGroupParams } from '../types';

export async function fetchPartnerTariffGroupTariffs(
  params: TariffGroupParams,
) {
  try {
    const header = await AuthHelpers.authHeader();
    const response = await Services.apiService.get<Tariff[]>(
      `/partners/${params.partnerId}/tariffs/groups/${params.groupId}/tariffs`,
      { headers: { ...header } },
    );
    return response.data;
  } catch (error: any) {
    if (!error.response) {
      throw error;
    }
    return Promise.reject(error.response.data as Types.HttpError);
  }
}
