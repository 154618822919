import { PropsWithChildren } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useLocation, useParams } from 'react-router-dom';
import { usePartnerId } from 'core/providers/PartnerProvider';
import { useTransactionCalculationsQuery } from 'features/transactions/queries';
import { Descriptions, Empty, Spin, Table, Typography } from 'antd';
import { useAppSelector } from 'store';
import { Padding } from 'components/Padding';
import { secsToTime } from 'core/utils';

import { useTransactionCalculationsColumns } from './useTransactionCalculationsColumns';
import styles from './TransactionCalculations.module.css';
import type { TransactionLabels } from './types';

interface Props {}

export function TransactionCalculations(props: PropsWithChildren<Props>) {
  const { transactionId } = useParams<{ transactionId?: string }>();
  const location = useLocation();
  const partnerId = usePartnerId();
  const { isError, isLoading, data } = useTransactionCalculationsQuery(
    partnerId,
    transactionId!,
    true,
    {
      enabled: !!partnerId && !!transactionId,
    },
  );

  const { t } = useTranslation();
  const { currency } = useAppSelector((state) => state.transactions);

  const columns = useTransactionCalculationsColumns();

  const getTranslatedLabelWithCurrency = (key: TransactionLabels) => {
    return `${t(`transactions.labels.${key}`)} (${currency})`;
  };

  if (isLoading) {
    return <Spin />;
  }

  if (isError) {
    return <div className={styles.container}>{t('common.status.failed')}</div>;
  }

  if (Object.keys(data).length === 0) {
    return (
      <div className={styles.container}>
        <Empty />
      </div>
    );
  }

  return (
    <>
      <Padding isHorizontal bottomBorder>
        <Typography.Title level={4} style={{ margin: 0 }}>
          {t('transactions.titles.transaction-calculation-details')}
        </Typography.Title>
      </Padding>
      <div className={styles.container}>
        <Descriptions layout="vertical">
          <Descriptions.Item label={t('transactions.labels.transaction-id')}>
            <Link to={location.pathname.replace('/calculations', '')}>
              {data.transactionId}
            </Link>
          </Descriptions.Item>
          <Descriptions.Item
            label={getTranslatedLabelWithCurrency('energy-amount')}
          >
            {data.totalGrouped?.energy?.amount}
          </Descriptions.Item>
          <Descriptions.Item
            label={getTranslatedLabelWithCurrency('energy-vat-amount')}
          >
            {data.totalGrouped?.energy?.vatAmount}
          </Descriptions.Item>
          <Descriptions.Item
            label={getTranslatedLabelWithCurrency('flat-amount')}
          >
            {data.totalGrouped?.flat?.amount}
          </Descriptions.Item>
          <Descriptions.Item
            label={getTranslatedLabelWithCurrency('flat-vat-amount')}
          >
            {data.totalGrouped?.flat?.vatAmount}
          </Descriptions.Item>
          <Descriptions.Item
            label={getTranslatedLabelWithCurrency('time-amount')}
          >
            {data.totalGrouped?.time?.amount}
          </Descriptions.Item>
          <Descriptions.Item
            label={getTranslatedLabelWithCurrency('time-vat-amount')}
          >
            {data.totalGrouped?.time?.vatAmount}
          </Descriptions.Item>
          <Descriptions.Item label={t('transactions.labels.total-amount')}>
            {data.totalAmount?.amount}
          </Descriptions.Item>
          <Descriptions.Item label={t('transactions.labels.total-kwh')}>
            {data.totalKwh}
          </Descriptions.Item>
          <Descriptions.Item label={t('transactions.labels.total-sec')}>
            {secsToTime(data.totalSec)}
          </Descriptions.Item>
        </Descriptions>
        <Table
          style={{ marginTop: 16 }}
          columns={columns}
          size={'small'}
          pagination={false}
          dataSource={data.items}
          scroll={{ x: 'max-content' }}
        />
      </div>
    </>
  );
}
