import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Form, Input, Select, Spin } from 'antd';
import { SelectOptions } from 'core/types/SelectOptions';
import {
  getCurrenciesFromCountries,
  getCurrenciesSelectOptions,
} from 'core/utils';
import { useErrorTranslation } from 'i18n/useErrorTranslation';
import { useCountries } from 'features/catalog';
import { useChargePointsUpdateMutation } from 'features/charge-points/queries';
import { useIsSubpartner, usePartnerId } from 'core/providers/PartnerProvider';
import { usePartnerHashtagsQuery } from 'features/hashtags/queries';
import { useChargePointsDetailsContext } from 'pages/ChargePointsDetails/ChargePointsDetailsContext';
import { useSubpartnersQuery } from 'features/subpartners';
import { showToastMessage } from 'core/utils/showToastMessage';

import { BusinessDetails } from './BusinessDetails';

export const DescriptionTab = () => {
  const partnerId = usePartnerId();
  const { chargePoint, isExternalResource } = useChargePointsDetailsContext();
  const isSubpartner = useIsSubpartner();
  const mutation = useChargePointsUpdateMutation();
  const [currencies, setCurrencies] = useState<SelectOptions[]>([]);
  const { data: countries } = useCountries();

  useEffect(() => {
    if (!countries) return;
    const currencies = getCurrenciesFromCountries(countries);

    setCurrencies(
      currencies.length ? getCurrenciesSelectOptions(currencies) : [],
    );
  }, [countries]);

  const hashtagsQuery = usePartnerHashtagsQuery([partnerId], {
    enabled: !!partnerId && !isSubpartner,
  });

  const { data: subpartners, isLoading: isSubpartnersLoading } =
    useSubpartnersQuery(partnerId, { size: 999 }, { enabled: !isSubpartner });

  const { t } = useTranslation();
  const { t: errorT } = useErrorTranslation();

  const onFinish = (values: Record<string, any>) => {
    const data = {
      id: chargePoint.id,
      name: values.name,
      currency: values.currency,
      deviceId: chargePoint.device?.deviceId,
      partnerId: chargePoint.partner?.id,
      termsUrl: values.termsUrl,
      hashTags: values.hashTags,
      description: values.description,
      ownerPartnerId: values.ownerPartnerId ?? '',
      qrCode: values.qrCode ?? '',
    };
    mutation.mutate(data, {
      onSuccess: () => {
        showToastMessage('success', t('common.status.success'));
      },
      onError: (error) =>
        showToastMessage('error', errorT(error?.translationKey, error.message)),
    });
  };

  if (hashtagsQuery.isInitialLoading) {
    return <Spin />;
  }

  return (
    <>
      <BusinessDetails />
      <Form
        layout={'vertical'}
        name="normal_login"
        className="login-form"
        initialValues={{
          name: chargePoint.name,
          currency: chargePoint.currency,
          description: chargePoint.description,
          ocppVersion: chargePoint.ocppVersion,
          sn: chargePoint.device?.chargePointSerialNumber,
          vendor: chargePoint.device?.chargePointVendor,
          model: chargePoint.device?.chargePointModel,
          deviceId: chargePoint.device?.deviceId,
          termsUrl: chargePoint.termsUrl,
          hashTags: chargePoint.hashTags ?? [],
          qrCode: chargePoint.qrCode ?? '',
          ownerPartnerId:
            chargePoint.ownerPartnerId === chargePoint.partner?.id
              ? ''
              : chargePoint.ownerPartnerId,
        }}
        onFinish={onFinish}
        disabled={isExternalResource || isSubpartner}
      >
        <Form.Item name="name" label={`${t('chargepoints.labels.name')}: `}>
          <Input />
        </Form.Item>

        <Form.Item
          name="description"
          label={`${t('chargepoints.labels.description')}: `}
        >
          <Input.TextArea rows={4} maxLength={1024} />
        </Form.Item>

        <Form.Item
          name="currency"
          label={`${t('chargepoints.labels.currency')}:`}
          style={{ width: '100%' }}
        >
          <Select options={currencies} />
        </Form.Item>

        <Form.Item
          name="sn"
          label={`${t('chargepoints.labels.serial-number')}: `}
        >
          <Input disabled />
        </Form.Item>

        <Form.Item
          name="ocppVersion"
          label={`${t('chargepoints.labels.ocpp-version')}: `}
        >
          <Input disabled />
        </Form.Item>

        <Form.Item name="vendor" label={`${t('chargepoints.labels.vendor')}: `}>
          <Input disabled />
        </Form.Item>

        <Form.Item name="model" label={`${t('chargepoints.labels.model')}: `}>
          <Input disabled />
        </Form.Item>

        <Form.Item
          name="deviceId"
          label={`${t('chargepoints.labels.device-id')}: `}
        >
          <Input disabled />
        </Form.Item>

        <Form.Item
          name="termsUrl"
          label={`${t('chargepoints.labels.terms-url')}: `}
        >
          <Input />
        </Form.Item>

        <Form.Item
          name="qrCode"
          label={`${t('chargepoints.labels.qr-code')}: `}
        >
          <Input />
        </Form.Item>

        <Form.Item
          name="ownerPartnerId"
          label={`${t('chargepoints.labels.select-owner')}:`}
        >
          <Select
            allowClear
            placeholder={'Subpartner name'}
            options={(subpartners ?? []).map((subpartner) => ({
              label: subpartner.name,
              value: subpartner.id,
            }))}
          />
        </Form.Item>

        <Form.Item
          name="hashTags"
          label={`${t('chargepoints.labels.groups')}: `}
        >
          <Select
            mode="tags"
            placeholder={t('chargepoints.placeholders.select-groups')}
            options={(hashtagsQuery.data ?? [])
              .filter(Boolean)
              .map((hashtag) => ({
                label: hashtag,
                value: hashtag,
              }))}
          />
        </Form.Item>

        <Form.Item style={{ marginBottom: 0 }}>
          <Button
            type="primary"
            htmlType="submit"
            loading={mutation.isLoading}
            className="login-form-button"
          >
            {t('common.actions.save-changes')}
          </Button>
        </Form.Item>
      </Form>
    </>
  );
};
