import * as Types from 'core/types';
import { useQuery, UseQueryOptions } from '@tanstack/react-query';

import { fetchPartnerTariffGroupTariffs } from '../fetches';
import { Tariff } from '../types';

export const USE_PARTNER_TARIFF_GROUP_TARIFFS_QUERY_KEY =
  'partners.tariffs.group.tariffs';

export function usePartnerTariffGroupTariffsQuery(
  params: Parameters<typeof fetchPartnerTariffGroupTariffs>[0],
  options?: UseQueryOptions<Tariff[], Types.HttpError, Tariff[], any>,
) {
  return useQuery(
    [USE_PARTNER_TARIFF_GROUP_TARIFFS_QUERY_KEY, params],
    () => fetchPartnerTariffGroupTariffs(params),
    {
      refetchOnWindowFocus: false,
      refetchInterval: false,
      refetchOnMount: true,
      refetchOnReconnect: false,
      refetchIntervalInBackground: false,
      ...options,
    },
  );
}
