import { Descriptions, Table } from 'antd';
import { useTranslation } from 'react-i18next';
import type { Transaction } from 'features/transactions/types';

import { useTransactionColumns } from './useTransactionColumns';

export const TransactionInfo = ({
  transaction,
}: {
  transaction: Transaction;
}) => {
  const { t } = useTranslation();

  const transactionColumns = useTransactionColumns();

  return (
    <>
      <Descriptions title={t('transactions.titles.transaction-info')} />
      <Table
        size={'small'}
        pagination={false}
        scroll={{ x: 'max-content' }}
        dataSource={[transaction]}
        columns={transactionColumns}
      />
    </>
  );
};
