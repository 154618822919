import React, { PropsWithChildren } from 'react';
import { LogoutOutlined, UserOutlined } from '@ant-design/icons';
import {
  Avatar,
  Dropdown,
  Layout as BaseLayout,
  Menu,
  MenuProps,
  theme,
  Button,
} from 'antd';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import { Logo } from 'views/Logo';
import { Link, Outlet, useNavigate } from 'react-router-dom';
import { useAuthLogout } from 'features/auth/hooks';
import { LanguageSwitcher } from 'i18n/LangSwitcher';

import styles from './Layout.module.css';
import { useMenuItems } from './useMenuItems';

const { Header, Content, Footer, Sider } = BaseLayout;

type MenuItem = Required<MenuProps>['items'][number];

export const Layout = ({ children }: PropsWithChildren) => {
  const navigate = useNavigate();
  const { clearAll: logout } = useAuthLogout();
  const menuItems = useMenuItems();

  const { token } = theme.useToken();

  const { t } = useTranslation();

  const siderBg = token.Layout?.siderBg || '#fff';

  return (
    <BaseLayout style={{ minHeight: '100vh' }}>
      <Sider style={{ width: '200px' }} />
      <Sider
        style={{
          position: 'fixed',
          left: 0,
          top: 0,
          height: '100vh',
          width: '200px',
        }}
      >
        <div
          style={{
            paddingTop: 10,
            paddingBottom: 10,
            minHeight: 64,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            textAlign: 'center',
            backgroundColor: '#fff',
          }}
        >
          <Link to="/">
            <Logo width={75} />
          </Link>
        </div>
        <Menu
          theme={'light'}
          style={{ backgroundColor: siderBg, height: '70%', overflowY: 'auto' }}
          mode="inline"
          inlineIndent={12}
          items={menuItems}
        />
        <Button
          type="default"
          href="https://chargingplatform.ru/help"
          target="_blank"
          style={{
            position: 'absolute',
            left: '32px',
            bottom: '32px',
          }}
        >
          {t('misc.help-center')}
        </Button>
      </Sider>
      <BaseLayout className="site-layout">
        <Header className={styles.layout_header} style={{ background: '#fff' }}>
          <LanguageSwitcher />
          <div>
            <Dropdown
              menu={{
                items: [
                  {
                    key: 'profile',
                    label: t('navigation.profile'),
                    icon: <UserOutlined />,
                    onClick: () => navigate('/partners/profile'),
                  },
                  {
                    key: 'logout',
                    label: t('navigation.logout'),
                    icon: <LogoutOutlined />,
                    onClick: logout,
                  },
                ],
              }}
            >
              <Avatar size={32} icon={<UserOutlined />} />
            </Dropdown>
          </div>
        </Header>

        <Content style={{ margin: '16px 16px' }}>
          <Outlet />
          {children}
        </Content>

        <Footer style={{ textAlign: 'center' }}>
          Права принадлежат ООО "Софикс" <br />
          ОГРН 1197746634535, ИНН 7743320282, КПП 774301001 <br />
          ©2022 – {dayjs().get('year')}
        </Footer>
      </BaseLayout>
    </BaseLayout>
  );
};
