import { Col, DatePicker, Form, Row } from 'antd';
import { useTranslation } from 'react-i18next';
import { dateFormatString, timeFormatString } from 'core/utils';

import { validateTime } from '../utils';

const { RangePicker } = DatePicker;

export const ValidityPeriodRow = () => {
  const { t } = useTranslation();

  return (
    <Row className="validity-period-row">
      <Col lg={24} className="label">
        {t('pricing.labels.element-validity-period')}:
      </Col>
      <Col lg={24}>
        <Form.Item
          name={['restriction', 'validityPeriod']}
          rules={[validateTime]}
        >
          <RangePicker
            showTime={{ format: 'HH:mm' }}
            format={`${dateFormatString} ${timeFormatString}`}
          />
        </Form.Item>
      </Col>
    </Row>
  );
};
