import { ColumnsType } from 'antd/es/table';
import { HolderOutlined } from '@ant-design/icons';
import {
  TariffElementRestriction,
  TariffElement,
} from 'features/partners-tariffs-groups/types';
import { Button } from 'antd';
import { useTranslation } from 'react-i18next';

import { RestrictionView } from './RestrictionView';
import { ElementsListColumns } from './types';

export const useElementsListColumns = ({
  tariffCurrency,
  onDelete,
  isReadOnly,
  isSortingDisabled,
}: ElementsListColumns): ColumnsType<
  TariffElement & { key: string; sorting?: string }
> => {
  const { t } = useTranslation();

  return [
    {
      dataIndex: 'sorting',
      width: 32,
      render: () => (
        <Button
          type="text"
          size="small"
          icon={<HolderOutlined />}
          style={{ cursor: 'move' }}
        />
      ),
      hidden: isSortingDisabled,
    },
    {
      title: t('pricing.columns.measured-quantity'),
      width: '15%',
      dataIndex: 'tariffPrices',
      key: 'tariffPrices',
      render: (prices: TariffElement['tariffPrices']) =>
        Object.entries(prices).map(([type], idx) => (
          <div key={idx}>
            <div>{type}</div>
          </div>
        )),
    },
    {
      title: t('pricing.columns.tariff'),
      dataIndex: 'tariffPrices',
      key: 'tariffPrices',
      render: (prices: TariffElement['tariffPrices']) =>
        Object.entries(prices).map(([, price], idx) => (
          <div key={idx}>
            <div>
              {price.price} {tariffCurrency}{' '}
              {price.vatPercent ? `/ ${price.vatPercent}%` : ''}
            </div>
          </div>
        )),
    },
    {
      title: t('pricing.columns.tariff-rules'),
      dataIndex: 'restrictions',
      key: 'restrictions',
      render: (list: TariffElementRestriction[]) =>
        list
          ? list.map((restriction, idx) => (
              <RestrictionView key={idx} restriction={restriction} />
            ))
          : '-',
    },
    {
      render: (_, item: TariffElement) =>
        !isReadOnly && (
          <Button
            onClick={(evt) => {
              evt.stopPropagation();
              onDelete(item);
            }}
            type={'link'}
          >
            {t('common.actions.delete')}
          </Button>
        ),
      align: 'right',
    },
  ];
};
