import { FC } from 'react';
import { Transaction } from 'features/transactions/types';
import { ResTransactionMetrics } from 'features/transactions/types/responses';

import styles from '../TransactionDetails.module.css';

import { TarificationInfo } from './TarificationInfo';
import { TarifficationDetails } from './TarifficationDetails';
import { TransactionInvoicing } from './TransactionInvoicing';
import { TransactionInfo } from './TarifficationInfo';
import { TransactionMetrics } from './TransactionMetrics';

interface Props {
  transaction: Transaction;
  metrics?: ResTransactionMetrics;
}

export const Details: FC<Props> = ({ transaction, metrics }) => {
  return (
    <>
      <div className={styles.transaction_container__block}>
        <TransactionInfo transaction={transaction} />
      </div>
      {!!transaction.details?.tariff && (
        <>
          <div className={styles.transaction_container__block}>
            <TarificationInfo tariff={transaction.details.tariff} />
          </div>
          <div className={styles.transaction_container__block}>
            <TarifficationDetails tariff={transaction.details.tariff} />
          </div>
        </>
      )}
      {!!transaction.details.invoicing && (
        <div className={styles.transaction_container__block}>
          <TransactionInvoicing invoicing={transaction.details.invoicing} />
        </div>
      )}
      {!!metrics?.items?.length && (
        <div className={styles.transaction_container__block}>
          <TransactionMetrics metrics={metrics} />
        </div>
      )}
    </>
  );
};
