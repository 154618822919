import { useEffect, useMemo, useState } from 'react';
import { Button, Form, Divider, Empty, message, App } from 'antd';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from '@tanstack/react-query';
import { pick } from 'lodash-es/';
import {
  USE_LOCATION_PARTNER_QUERY_KEY,
  useLocationUpdateMutation,
} from 'features/locations/queries';
import { useIsSubpartner, usePartnerId } from 'core/providers/PartnerProvider';
import { useErrorTranslation } from 'i18n/useErrorTranslation';

import { LocationFormFields } from '../LocationFormFields';

import { FormValues, LocationDetailsFormProps } from './types';

const defaultLocation: FormValues = {
  lat: 0,
  lon: 0,
  countryCode: '',
  state: '',
  city: '',
  address: '',
  postalCode: '',
  timeZone: '',
  facilities: [],
  parkingType: '',
  ownerPartnerId: '',
};

export const LocationDetailsForm = ({ location }: LocationDetailsFormProps) => {
  const partnerId = usePartnerId();
  const isSubpartner = useIsSubpartner();
  const { t } = useTranslation();
  const { t: errorT } = useErrorTranslation();
  const [messageApi, contextHolder] = message.useMessage();
  const { modal } = App.useApp();
  const [isFormChanged, setIsFormChanged] = useState(false);

  const [form] = Form.useForm<FormValues>();
  const countryCode = Form.useWatch('countryCode', form);
  const timezone = Form.useWatch('timeZone', form);

  const [isLoading, setLoading] = useState(false);

  const queryClient = useQueryClient();

  const locationMutation = useLocationUpdateMutation();

  const initialFormValues = useMemo((): FormValues => {
    return {
      ...(location
        ? (pick(location, Object.keys(defaultLocation)) as FormValues)
        : defaultLocation),
    };
  }, [location]);

  useEffect(() => {
    form.setFieldsValue(initialFormValues);
  }, [initialFormValues, form]);

  if (!location) {
    return <Empty />;
  }

  const handleSubmit = (values: FormValues) => {
    modal.confirm({
      title: t('locations.titles.update-location'),
      content: t('locations.text.confirm-update-location'),
      onOk: () => {
        locationMutation
          .mutateAsync([partnerId, location.id, values])
          .then((location) => {
            queryClient.setQueryData(
              [USE_LOCATION_PARTNER_QUERY_KEY, partnerId, location.id],
              location,
            );

            messageApi.open({
              type: 'success',
              content: t('common.status.success'),
            });
          })
          .catch((error) => {
            messageApi.open({
              type: 'error',
              content: errorT(error?.translationKey, error.message),
            });
          });
      },
    });
  };

  return (
    <>
      {contextHolder}
      <Form
        form={form}
        layout={'vertical'}
        name="location"
        disabled={isLoading || isSubpartner}
        initialValues={initialFormValues}
        onFinish={handleSubmit}
        onValuesChange={() => setIsFormChanged(true)}
      >
        <LocationFormFields
          form={form}
          countryCode={countryCode}
          timezone={timezone}
          setIsLoading={setLoading}
          location={location}
          setIsFormChanged={setIsFormChanged}
        />
        <Divider />

        <Button
          type="primary"
          htmlType="submit"
          loading={locationMutation.isLoading}
          disabled={!isFormChanged}
        >
          {t('common.actions.save-changes')}
        </Button>
      </Form>
    </>
  );
};
