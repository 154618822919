import { Form, Select } from 'antd';
import { useTranslation } from 'react-i18next';
import type { PartnerHashTags } from 'features/hashtags/types/HashTags';

import { RowWithLabel } from './RowWithLabel';

export const ChargepointHashTagsSelector = ({
  hashtags,
}: {
  hashtags: PartnerHashTags;
}) => {
  const { t } = useTranslation();

  return (
    <RowWithLabel label={`${t('pricing.labels.chargepoints-groups')}:`}>
      <Form.Item name={['restriction', 'cpHashTags']}>
        <Select
          mode="tags"
          allowClear
          maxTagCount={4}
          placeholder={t('pricing.placeholders.select-groups')}
          options={hashtags.filter(Boolean).map((hashtag) => ({
            label: hashtag,
            value: hashtag,
          }))}
        />
      </Form.Item>
    </RowWithLabel>
  );
};
