import dayjs from 'dayjs';
import {
  Tariff,
  TariffFormUpdateData,
  TariffFormValues,
} from 'features/partners-tariffs-groups/types';

export const toFormValues = (tariff?: Tariff): TariffFormValues => {
  return tariff
    ? {
        ...tariff,
        validRange: [dayjs(tariff.validFrom), dayjs(tariff.validTo)],
      }
    : getEmptyTariff('RUB');
};

export const fromFormValues = (
  values: TariffFormValues,
): TariffFormUpdateData => {
  return {
    ...values,
    validFrom: values.validRange?.[0]?.toISOString() ?? '',
    validTo: values.validRange?.[1]?.toISOString() ?? '',
  };
};

export const getEmptyTariff = (currency: string): TariffFormValues => {
  return {
    name: '',
    url: '',
    type: 'reg',
    currency,
    validRange: undefined,
    minPrice: {
      amount: 0,
      vatAmount: 0,
    },
    maxPrice: {
      amount: 0,
      vatAmount: 0,
    },
    purposes: ['gen', 'roaming'],
  };
};
