import { useQueryClient } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import { useErrorTranslation } from 'i18n/useErrorTranslation';
import {
  Alert,
  Button,
  Form,
  Input,
  InputNumber,
  Select,
  Space,
  Spin,
  Typography,
} from 'antd';
import { useNavigate } from 'react-router-dom';
import {
  USE_PARTNER_BALANCING_PROFILES_QUERY_KEY,
  useLoadBalancingProfileCreateMutation,
} from 'features/balancing-profiles';
import { usePartnerId } from 'core/providers/PartnerProvider';
import { usePartnerHashtagsQuery } from 'features/hashtags/queries';
import { Padding } from 'components/Padding';

const { Option } = Select;

const fakeFields = () => {
  const balancePriorityOptions = [
    {
      label: 'Равномерное распределение мощности',
      value: 'equal',
    },
    {
      label: 'Приоритет FIFO (первый получает больше)',
      value: 'fifo',
    },
    {
      label: 'Приоритет освобождения (максимальная мощность максимальному)',
      value: 'release',
    },
    {
      label: 'Приоритет дозаряда (максимальная мощность минимальному)',
      value: 'load',
    },
  ];

  const externalBalancerOptions = [
    {
      label: 'SETEC MPower',
      value: 'setec',
    },
    {
      label: 'Smart balancer',
      value: 'smart',
    },
  ];

  const shareWithOptions = [
    {
      label: 'Все',
      value: 'all',
    },
    {
      label: 'Только быстрые (DC)',
      value: 'dc',
    },
  ];

  return (
    <>
      <Form.Item
        name="balancingPriority"
        label={'Выберите приоритет балансировки:'}
      >
        <Select
          placeholder={'Выберите приоритет балансировки'}
          options={balancePriorityOptions}
        />
      </Form.Item>
      <Form.Item
        name="externalBalancer"
        label={'Внешний балансировщик нагрузки:'}
      >
        <Select
          placeholder={'Внешний балансировщик нагрузки'}
          options={externalBalancerOptions}
        />
      </Form.Item>
      <Form.Item name="balancerUrl" label={'URL для данных балансировщика:'}>
        <Input
          addonBefore="https://"
          inputMode="url"
          placeholder={'URL для данных балансировщика'}
        />
      </Form.Item>
      <Form.Item
        name="shareWith"
        label={'Распространять на станции в группе::'}
      >
        <Select
          placeholder={'Распространять на станции в группе:'}
          options={shareWithOptions}
        />
      </Form.Item>
    </>
  );
};

export function BalancingProfilesCreate() {
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const create = useLoadBalancingProfileCreateMutation();
  const partnerId = usePartnerId();
  const hashtagsQuery = usePartnerHashtagsQuery([partnerId], {
    enabled: !!partnerId,
  });

  const { t } = useTranslation();
  const { t: errorT } = useErrorTranslation();

  const handleCreate = (values: any) => {
    create
      .mutateAsync([
        partnerId,
        {
          name: values.name,
          maxCurrentA:
            values.limit.type === 'maxCurrentA'
              ? values.limit.value
              : undefined,
          maxPowerW:
            values.limit.type === 'maxPowerW' ? values.limit.value : undefined,
          cpTags: values.cpTags,
        },
      ])
      .then(() => {
        queryClient.invalidateQueries([
          USE_PARTNER_BALANCING_PROFILES_QUERY_KEY,
        ]);
        navigate('/balancing-profiles');
      });
  };

  if (hashtagsQuery.isLoading) {
    return <Spin />;
  }

  return (
    <div>
      <Padding>
        <Typography.Title level={4} style={{ margin: 0 }}>
          {t('balancing_profiles.titles.create-balancing-profile')}
        </Typography.Title>
      </Padding>
      <Padding>
        <Form
          form={form}
          layout={'vertical'}
          name="balancing-profile-create"
          onFinish={handleCreate}
        >
          <Form.Item
            name="name"
            label={`${t('balancing_profiles.labels.name')}:`}
          >
            <Input />
          </Form.Item>

          <Form.Item label={`${t('balancing_profiles.labels.limit')}:`}>
            <Space.Compact style={{ display: 'flex' }}>
              <Form.Item
                name={['limit', 'type']}
                rules={[{ required: true }]}
                noStyle
              >
                <Select
                  placeholder={t(
                    'balancing_profiles.placeholders.select-limit-type',
                  )}
                  style={{ width: 'auto' }}
                >
                  <Option value="maxCurrentA">
                    {t('balancing_profiles.columns.max-current-a')}
                  </Option>
                  <Option value="maxPowerW">
                    {t('balancing_profiles.columns.max-power-w')}
                  </Option>
                </Select>
              </Form.Item>
              <Form.Item
                name={['limit', 'value']}
                rules={[{ required: true }]}
                noStyle
              >
                <InputNumber style={{ width: '100%' }} />
              </Form.Item>
            </Space.Compact>
          </Form.Item>

          <Form.Item
            name="cpTags"
            label={`${t('balancing_profiles.labels.tags')}:`}
          >
            <Select
              mode="tags"
              placeholder={t('balancing_profiles.placeholders.select-tags')}
              options={(hashtagsQuery.data ?? [])
                .filter(Boolean)
                .map((hashtag) => ({
                  label: hashtag,
                  value: hashtag,
                }))}
            />
          </Form.Item>
          {process.env.REACT_APP_ENV === 'rostelekom' && fakeFields()}
          {!!create.error && (
            <Form.Item>
              <Alert
                type={'error'}
                description={errorT(
                  create.error?.translationKey,
                  create.error?.message,
                )}
              />
            </Form.Item>
          )}
          <Form.Item>
            <Button type="primary" htmlType="submit" loading={create.isLoading}>
              {t('common.actions.submit')}
            </Button>
          </Form.Item>
        </Form>
      </Padding>
    </div>
  );
}
