import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';

export const useMetricsColumns = () => {
  const { t } = useTranslation();
  
  return [
    {
      title: t('transactions.columns.timestamp'),
      dataIndex: 'timestamp',
      key: 'timestamp',
      render: (timestamp: string) =>
        timestamp ? dayjs(timestamp).format('DD/MM/YYYY HH:mm') : '-',
    },
    {
      title: t('transactions.columns.connector-id'),
      dataIndex: 'connectorId',
      key: 'connectorId',
    },

    {
      title: t('transactions.columns.current-import'),
      dataIndex: 'currentImport',
      key: 'currentImport',
    },
    {
      title: t('transactions.columns.energy-active-import-reg'),
      dataIndex: 'energyActiveImportReg',
      key: 'energyActiveImportReg',
      render: (energyActiveImportReg: number) =>
        energyActiveImportReg
          ? Math.round((energyActiveImportReg + Number.EPSILON) * 100) / 100
          : '-',
    },
    {
      title: t('transactions.columns.power-active-import'),
      dataIndex: 'powerActiveImport',
      key: 'powerActiveImport',
    },
    {
      title: t('transactions.columns.power-offered'),
      dataIndex: 'powerOffered',
      key: 'powerOffered',
    },
    {
      title: t('transactions.columns.soc'),
      dataIndex: 'soc',
      key: 'soc',
    },
    {
      title: t('transactions.columns.voltage'),
      dataIndex: 'voltage',
      key: 'voltage',
    },
  ];
};
