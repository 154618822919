import { PropsWithChildren, useEffect, useState } from 'react';
import { ConfigProvider, ThemeConfig, App } from 'antd';
import { useTranslation } from 'react-i18next';
import { Locale } from 'antd/es/locale';

const defaultTheme: ThemeConfig = {
  components: {
    Layout: {
      siderBg: '#fff',
    },
    Card: {
      colorBgContainer: '#fafafa',
    },
    Tag: {
      colorBorder: 'red',
    },
    Segmented: {
      itemSelectedBg: '#1677ff',
      itemHoverBg: '#fff',
      itemActiveBg: '#fff',
      itemSelectedColor: '#fff',
      trackPadding: '4px',
    },
  },
};

const rostelekomTheme: ThemeConfig = {
  token: {
    colorPrimary: '#4E119C',
    colorBgContainer: '#fff',
  },
  components: {
    Layout: {
      siderBg: '#4E119C',
    },
    Menu: {
      itemColor: '#fff',
      itemHoverColor: '#ccc',
      itemSelectedColor: '#fff',
      itemSelectedBg: '#B277FF57',
    },
    Segmented: {
      itemSelectedBg: '#4E119C',
      itemHoverBg: '#fff',
      itemActiveBg: '#fff',
      itemSelectedColor: '#fff',
      trackPadding: '4px',
    },
  },
};

export const AntdProvider = ({ children }: PropsWithChildren) => {
  const [locale, setLocale] = useState<Locale | undefined>();
  const { i18n } = useTranslation();

  const currentTheme =
    process.env.REACT_APP_ENV === 'rostelekom' ? rostelekomTheme : defaultTheme;

  useEffect(() => {
    import('./i18n/antdLocales').then(({ antdLocales }) => {
      setLocale(antdLocales[i18n.languages[0] as keyof typeof antdLocales]);
    });
  }, [i18n.languages]);

  return (
    <ConfigProvider locale={locale} theme={currentTheme}>
      <App>{children}</App>
    </ConfigProvider>
  );
};
