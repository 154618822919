import { Button, Empty, Modal, Table } from 'antd';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useErrorTranslation } from 'i18n/useErrorTranslation';
import { useChargePointsUpdateMutation } from 'features/charge-points/queries';
import { ConnectorType } from 'features/charge-points/types';
import {
  connectorStatusColor,
  PowerType,
  powerTypeLabels,
  ConnectorFormat,
  connectorFormatLabels,
  connectorStatusLabel,
} from 'components/view/ConnectorLabel/connectors-labels.const';
import { useChargePointsDetailsContext } from 'pages/ChargePointsDetails/ChargePointsDetailsContext';
import ConnectorEditForm from 'pages/ChargePointsDetails/tabs/ConnectorsTab/ConnectorEditForm';
import { ConnectorLabel } from 'components/view/ConnectorLabel';
import { Connector, NamedConnector } from 'core/types/Connector';
import { useIsSubpartner } from 'core/providers/PartnerProvider';
import { showToastMessage } from 'core/utils/showToastMessage';

import s from './ConnectorsTab.module.css';

export const ConnectorsTab = () => {
  const [connectorUpdate, setConnectorUpdate] = useState<NamedConnector | null>(
    null,
  );
  const { chargePoint, refetch, isExternalResource } =
    useChargePointsDetailsContext();

  const { t } = useTranslation();
  const { t: errorT } = useErrorTranslation();

  const isSubpartner = useIsSubpartner();

  const mutation = useChargePointsUpdateMutation();

  const handleUpdateClick = (item: { name: string }) => {
    const key = +item.name;
    const connector = chargePoint.connectors[key];
    if (connector) {
      setConnectorUpdate({ name: key, ...connector });
    }
  };

  const handleFormSubmit = (values: NamedConnector) => {
    const nextConnector = Object.fromEntries([
      [
        values.name,
        {
          type: values.type,
          powerType: values.powerType,
          maxVoltage: values.maxVoltage ? +values.maxVoltage : undefined,
          maxPower: values.maxPower ? +values.maxPower : undefined,
          maxAmperage: values.maxAmperage ? +values.maxAmperage : undefined,
          format: values.format,
          availability: values.availability,
          startChargingPluggedEv: values.startChargingPluggedEv,
          hidden: values.hidden,
          qrCode: values.qrCode,
        },
      ],
    ]);

    const data = {
      id: chargePoint.id,
      deviceId: chargePoint.device?.deviceId,
      partnerId: chargePoint.partner?.id,
      connectors: {
        ...chargePoint.connectors,
        ...nextConnector,
      },
    };

    mutation.mutate(data, {
      onSuccess: () => {
        refetch();
        setConnectorUpdate(null);
        showToastMessage('success', t('common.status.success'));
      },
      onError: (error) =>
        showToastMessage('error', errorT(error?.translationKey, error.message)),
    });
  };

  if (!chargePoint) {
    return <Empty />;
  }

  const columns = [
    {
      title: '#',
      dataIndex: 'name',
      key: 'name',
      fixed: 'left' as const,
    },
    {
      title: t('chargepoints.columns.type'),
      dataIndex: 'type',
      key: 'type',
      render: (type: ConnectorType) => <ConnectorLabel type={type} />,
    },
    {
      title: t('chargepoints.columns.status'),
      dataIndex: 'availability',
      key: 'availability',
      render: (availability: string) => (
        <span style={{ color: connectorStatusColor[availability] }}>
          {t(connectorStatusLabel[availability])}
        </span>
      ),
    },
    {
      title: t('chargepoints.columns.hidden'),
      dataIndex: 'hidden',
      key: 'hidden',
      render: (hidden: boolean) => (
        <span>
          {hidden ? t('chargepoints.text.yes') : t('chargepoints.text.no')}
        </span>
      ),
    },
    {
      title: t('chargepoints.columns.format'),
      dataIndex: 'format',
      key: 'format',
      render: (format: ConnectorFormat) => (
        <span>{t(connectorFormatLabels[format])}</span>
      ),
    },
    {
      title: t('chargepoints.columns.power-type'),
      dataIndex: 'powerType',
      key: 'powerType',
      render: (powerType: PowerType) => (
        <span>{t(powerTypeLabels[powerType])}</span>
      ),
    },
    {
      title: t('chargepoints.columns.max-voltage'),
      dataIndex: 'maxVoltage',
      key: 'maxVoltage',
    },
    {
      title: t('chargepoints.columns.max-power'),
      dataIndex: 'maxPower',
      key: 'maxPower',
    },
    {
      title: t('chargepoints.columns.max-amperage'),
      dataIndex: 'maxAmperage',
      key: 'maxAmperage',
    },
    {
      title: t('chargepoints.columns.start-charging-plugged-ev'),
      dataIndex: 'startChargingPluggedEv',
      key: 'startChargingPluggedEv',
      render: (startChargingPluggedEv: Connector['startChargingPluggedEv']) =>
        startChargingPluggedEv
          ? t('chargepoints.text.yes')
          : t('chargepoints.text.no'),
    },
    {
      title: '',
      key: 'action',
      render: (item: any) => (
        <Button
          disabled={isExternalResource || isSubpartner}
          onClick={() => handleUpdateClick(item)}
        >
          {t('common.actions.update')}
        </Button>
      ),
    },
  ];

  const dataSource = chargePoint.connectors
    ? Object.entries(chargePoint.connectors).map(([key, value]) => ({
        key,
        name: key,
        type: value.type,
        powerType: value.powerType ?? '?',
        maxVoltage: value.maxVoltage ?? '?',
        maxPower: value.maxPower ?? '?',
        maxAmperage: value.maxAmperage ?? '?',
        format: value.format,
        availability: value.availability ?? '?',
        startChargingPluggedEv: value.startChargingPluggedEv,
        hidden: value.hidden ?? false,
      }))
    : [];

  return (
    <>
      {dataSource && dataSource.length > 0 ? (
        <Table
          columns={columns}
          size="small"
          className={s.connectors_tab__table}
          dataSource={dataSource}
          scroll={{ x: 700 }}
          pagination={false}
        />
      ) : (
        <Empty />
      )}

      <Modal
        title={`${t('chargepoints.titles.edit-connector', {
          connectorName: connectorUpdate?.name,
        })}`}
        width={900}
        onCancel={() => setConnectorUpdate(null)}
        open={!!connectorUpdate}
        destroyOnClose
        footer={null}
      >
        {connectorUpdate && (
          <ConnectorEditForm
            connectorUpdate={connectorUpdate}
            onSubmit={handleFormSubmit}
            onClose={() => setConnectorUpdate(null)}
            isLoading={mutation.isLoading}
          />
        )}
      </Modal>
    </>
  );
};
