import {
  TariffElement,
  TariffElementPrices,
  TariffElementPriceType,
} from 'features/partners-tariffs-groups/types';
import dayjs, { Dayjs } from 'dayjs';
import type { Rule } from 'antd/es/form';
import { hasValue, isDefined } from 'core/utils/typeguards';
import { toTimeString, fromTimeString } from 'core/utils';

import type { ElementFormMode, ElementFormValues } from './types';

export const validateTime: Rule = () => ({
  validator(_, value: [Dayjs, Dayjs]) {
    if (value && value[0] && value[1]) {
      if (value[0].isSame(value[1]) || value[0].isAfter(value[1])) {
        return Promise.reject();
      }
    }
    return Promise.resolve();
  },
});

export const toFormValues = (element: TariffElement): ElementFormValues => {
  const restriction = element?.restrictions?.[0];

  if (!restriction) {
    return { ...element, restriction: {} };
  }

  return {
    ...element,
    restriction: {
      ...restriction,
      validityPeriod:
        restriction.startDate && restriction.endDate
          ? [dayjs(restriction.startDate), dayjs(restriction.endDate)]
          : undefined,
      time: restriction.time && {
        range: [
          fromTimeString(restriction.time.from),
          fromTimeString(restriction.time.to),
        ],
      },
    },
  };
};

const mergeTariffPrices = (
  tariffPrices: TariffElementPrices,
  newTariffPrices: TariffElementPrices,
): TariffElementPrices => {
  const newTariffPairs = Object.fromEntries(
    Object.entries(newTariffPrices).filter(
      ([key]) => !Object.keys(tariffPrices).includes(key),
    ),
  );

  const mergedResult = Object.fromEntries(
    Object.entries(tariffPrices)
      .map(([type, price]) => {
        const newPrice = newTariffPrices[type as TariffElementPriceType];

        if (hasValue(price?.price) && hasValue(newPrice?.price)) {
          return [type, { ...price, ...newPrice }];
        } else if (newPrice?.price === null) {
          return [];
        } else if (hasValue(price?.price)) {
          return [type, { ...price }];
        } else if (hasValue(newPrice?.price)) {
          return [type, { ...newPrice }];
        } else {
          return [];
        }
      })
      .filter(([key]) => hasValue(key)),
  );

  return {
    ...mergedResult,
    ...newTariffPairs,
  };
};

export const fromFormValues = (
  values: ElementFormValues,
  element: TariffElement,
  mode: ElementFormMode,
): TariffElement => {
  const { restriction, tariffPrices } = values;

  const mergedTariffPrices = mergeTariffPrices(
    element.tariffPrices,
    tariffPrices,
  );

  const { validityPeriod, time } = restriction;

  let safeTime;

  if (isDefined(time) && hasValue(time.range)) {
    safeTime = {
      from: toTimeString(time.range[0]),
      to: toTimeString(time.range[1]),
    };
  }

  const startDate =
    validityPeriod?.[0]?.toISOString() ?? element.restrictions?.[0]?.startDate;
  const endDate =
    validityPeriod?.[1]?.toISOString() ?? element.restrictions?.[0]?.endDate;

  delete restriction.validityPeriod;

  return {
    id: element.id,
    tariffPrices: mergedTariffPrices,
    restrictions: [
      {
        ...(element.restrictions && mode === 'simple'
          ? element.restrictions[0]
          : {}),
        ...restriction,
        startDate,
        endDate,
        time: safeTime,
      },
    ],
  };
};
