import { FC, memo } from 'react';
import dayjs from 'dayjs';
import { Form, Input, Row, Col, DatePicker, InputNumber, Select } from 'antd';
import {
  dateFormatString,
  timeFormatString,
  getCurrenciesSelectOptions,
  getPurposesSelectOptions,
} from 'core/utils';
import { TariffStatus } from 'core/utils/tariffUtils';
import { useTranslation } from 'react-i18next';

import { useTariffDetailsContext } from '../context/TariffDetailsContext';
import { RangeInputsRow } from '../ElementModal/components/RangeInputsRow';
import type { TariffFormProps } from '../ElementsList/types';

import s from './TariffForm.module.css';
import { toFormValues, fromFormValues } from './TariffForm.utils';

const { RangePicker } = DatePicker;

const TariffForm: FC<TariffFormProps> = ({
  tariff,
  onSubmit,
  form,
  currencies,
  onCurrencyChange,
  status = '',
}) => {
  const initialValues = toFormValues(tariff);
  const currenciesOptions = getCurrenciesSelectOptions(currencies);
  const options =
    status === TariffStatus.draft || !status
      ? ['gen', 'roaming']
      : tariff?.purposes || [];
  const purposes = getPurposesSelectOptions(options);
  const { isActiveTariff, isArchivedTariff, tariffCurrency } =
    useTariffDetailsContext();

  const { t } = useTranslation();

  const isTariffValidityExpired = dayjs().isAfter(
    dayjs(form.getFieldValue('validTo')),
  );

  const isReadOnlyInput = isActiveTariff || isArchivedTariff;
  const isReadOnlyDate =
    isArchivedTariff || (isActiveTariff && isTariffValidityExpired);

  return (
    <Form
      layout={'vertical'}
      initialValues={initialValues}
      onFinish={(values) => onSubmit(fromFormValues(values))}
      form={form}
      disabled={isArchivedTariff}
    >
      <Row gutter={16}>
        <Col span={16}>
          <Form.Item
            name="name"
            label={`${t('pricing.labels.tariff-name')}:`}
            rules={[{ required: true }]}
          >
            <Input
              placeholder={t('pricing.placeholders.tariff-name')}
              maxLength={120}
            />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item
            name="currency"
            label={`${t('pricing.labels.currency')}:`}
            className={s.wide}
          >
            <Select
              options={currenciesOptions}
              onChange={onCurrencyChange}
              disabled={isReadOnlyInput}
            />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={16}>
          <Form.Item name="url" label={`${t('pricing.labels.terms-url')}:`}>
            <Input
              placeholder={t('pricing.placeholders.tariff-terms-url')}
              maxLength={120}
            />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item
            name="purposes"
            label={`${t('pricing.labels.purposes')}:`}
            className={s.wide}
          >
            <Select
              mode="multiple"
              options={purposes}
              disabled={isReadOnlyInput}
            />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={16}>
          <Form.Item
            name="description"
            label={`${t('pricing.labels.description')}:`}
          >
            <Input.TextArea
              rows={5}
              maxLength={1024}
              placeholder={t('pricing.placeholders.enter-description')}
            />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item
            name="validRange"
            label={t('pricing.labels.tariff-validity-period')}
            className={s.wide}
            rules={[{ required: true }]}
          >
            <RangePicker
              showTime={{ showNow: false, showSecond: false }}
              showNow={false}
              format={`${dateFormatString} ${timeFormatString}`}
              className={s.wide}
              allowClear={false}
              disabled={isReadOnlyDate}
            />
          </Form.Item>
          <RangeInputsRow
            label={t('pricing.labels.tariff-price-range')}
            rowGutter={[8, 8]}
          >
            <>
              <Form.Item name={['minPrice', 'amount']} className={s.wide}>
                <InputNumber
                  className={s.wide}
                  placeholder={t('pricing.labels.min-price')}
                  min={0}
                  max={999999999}
                  addonAfter={'-'}
                  disabled={isReadOnlyInput}
                />
              </Form.Item>
              <Form.Item name={['maxPrice', 'amount']} className={s.wide}>
                <InputNumber
                  className={s.wide}
                  placeholder={t('pricing.labels.max-price')}
                  addonAfter={tariffCurrency}
                  min={0}
                  max={999999999}
                  disabled={isReadOnlyInput}
                />
              </Form.Item>
            </>
          </RangeInputsRow>
        </Col>
      </Row>
    </Form>
  );
};

export default memo(TariffForm);
