import { useCallback } from 'react';
import { App } from 'antd';
import { pick } from 'lodash-es';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useErrorTranslation } from 'i18n/useErrorTranslation';
import { usePartnerId } from 'core/providers/PartnerProvider';
import {
  usePartnerActiveTariffGroupUpdateMutation,
  usePartnerTariffGroupTariffCreateMutation,
  usePartnerTariffGroupTariffUpdateMutation,
} from 'features/partners-tariffs-groups/queries';
import {
  Tariff,
  TariffEditable,
  TariffUpdateActiveData,
  TariffUpdateDataWithElements,
} from 'features/partners-tariffs-groups/types';

export const useTariffMutations = () => {
  const { message } = App.useApp();
  const partnerId = usePartnerId();
  const { groupId, tariffId } = useParams();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { t: errorT } = useErrorTranslation();

  const updateMutation = usePartnerTariffGroupTariffUpdateMutation();
  const createMutation = usePartnerTariffGroupTariffCreateMutation();
  const updateActiveMutation = usePartnerActiveTariffGroupUpdateMutation();

  const isLoading =
    !!updateMutation.isLoading ||
    !!createMutation.isLoading ||
    !!updateActiveMutation.isLoading;

  const createNewTariff = useCallback(
    (tariffData: TariffUpdateDataWithElements) => {
      if (!groupId) return;

      createMutation.mutate([{ partnerId, groupId }, tariffData], {
        onSuccess: ({ id }) => {
          message.open({
            type: 'success',
            content: t('pricing.labels.draft-created'),
          });

          navigate(`../${id}`, {
            relative: 'path',
            replace: true,
          });
        },
        onError: (error) => {
          message.open({
            type: 'error',
            content: errorT(error?.translationKey, error.message),
          });
        },
      });
    },
    [createMutation, message, t, errorT, partnerId, groupId, navigate],
  );

  const updateDraftTariff = useCallback(
    (tariffData: TariffUpdateDataWithElements) => {
      if (!tariffId || !groupId) {
        console.warn('Tariff id or group id is not defined');
        return;
      }

      updateMutation.mutate([{ partnerId, tariffId, groupId }, tariffData], {
        onError: (error) => {
          message.open({
            type: 'error',
            content: errorT(error?.translationKey, error.message),
          });
        },
        onSuccess: () => {
          message.open({
            type: 'success',
            content: t('pricing.labels.draft-saved'),
          });
        },
      });
    },
    [tariffId, groupId, updateMutation, partnerId, message, errorT, t],
  );

  const updateActiveTariff = useCallback(
    (tariff: TariffUpdateDataWithElements) => {
      if (!tariffId || !groupId) {
        console.warn('Tariff id or group id is not defined');
        return;
      }

      const validFieldsForActiveTariff: (keyof TariffUpdateActiveData)[] = [
        'name',
        'description',
        'url',
        'validFrom',
        'validTo',
      ];

      const updateData = pick(tariff, validFieldsForActiveTariff);

      updateActiveMutation.mutate(
        [{ partnerId, tariffId, groupId }, updateData],
        {
          onError: (error) => {
            message.open({
              type: 'error',
              content: errorT(error?.translationKey, error.message),
            });
          },
          onSuccess: () => {
            message.open({
              type: 'success',
              content: t('pricing.labels.tariff-updated'),
            });
          },
        },
      );
    },
    [tariffId, groupId, updateActiveMutation, partnerId, message, errorT, t],
  );

  return {
    updateDraftTariff,
    createNewTariff,
    updateActiveTariff,
    isLoading,
  };
};
