import { useMemo } from 'react';
import { Space, Tabs, Typography, Spin, Empty, Tooltip } from 'antd';
import { useParams } from 'react-router-dom';
import {
  USE_CHARGE_POINT_QUERY_KEY,
  useChargePoint,
} from 'features/charge-points/queries';
import { useQueryClient } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import { Padding } from 'components/Padding';
import { usePartnerId } from 'core/providers/PartnerProvider';
import { EmergencyIcon } from 'components/EmergencyIcon';

import { getChargePointTabs } from './getChargePointTabs';
import {
  ChargePointsDetailsContext,
  ChargePointsDetailsContextType,
} from './ChargePointsDetailsContext';

export const ChargePointsDetails = () => {
  const { id = '' } = useParams<{ id: string }>();
  const queryClient = useQueryClient();

  const partnerId = usePartnerId();

  const { t } = useTranslation();

  const {
    data: chargePoint,
    isLoading,
    refetch,
  } = useChargePoint(partnerId, id, {
    withAvailability: true,
    withTariff: true,
    withEmergencyButtonTime: true,
    withLastConnectionTime: true,
  });

  const chargePointsDetailsContextValue: ChargePointsDetailsContextType | null =
    useMemo(() => {
      if (!chargePoint) return null;

      return {
        chargePoint,
        isExternalResource: typeof chargePoint.externalSource !== 'undefined',
        refetch,
        updateCache: (newChargePointData) => {
          queryClient.setQueryData(
            [USE_CHARGE_POINT_QUERY_KEY, chargePoint.id],
            newChargePointData,
          );
        },
      };
    }, [chargePoint, queryClient, refetch]);

  const chargePointTabs = chargePointsDetailsContextValue
    ? getChargePointTabs(t, chargePointsDetailsContextValue.isExternalResource)
    : [];

  if (isLoading) {
    return <Spin />;
  }

  if (!chargePoint) {
    return <Empty />;
  }

  return (
    <>
      <Padding bottomBorder>
        <Typography.Title
          level={4}
          style={{ display: 'flex', alignItems: 'center' }}
        >
          {!!chargePoint?.emergencyButtonTime && (
            <Tooltip title={t('chargepoints.text.emergency-button-pressed')}>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <EmergencyIcon />
              </div>
            </Tooltip>
          )}
          {chargePoint.name}
        </Typography.Title>
        <Space>
          <Typography.Text>{t('chargepoints.text.id')}: </Typography.Text>
          <Typography.Text type="secondary">{chargePoint.id}</Typography.Text>
        </Space>
      </Padding>
      <Padding noStart>
        {chargePointsDetailsContextValue && (
          <ChargePointsDetailsContext.Provider
            value={chargePointsDetailsContextValue}
          >
            <Tabs
              type="line"
              defaultActiveKey="description"
              size="large"
              items={chargePointTabs}
            />
          </ChargePointsDetailsContext.Provider>
        )}
      </Padding>
    </>
  );
};
