import * as Types from 'core/types';
import { useMutation, UseMutationOptions } from '@tanstack/react-query';
import { fetchPartnerTariffGroupTariffDelete } from 'features/partners-tariffs-groups/fetches/fetchPartnerTariffGroupTariffDelete';

export function usePartnerTariffGroupTariffDeleteMutation(
  options?: UseMutationOptions<
    unknown,
    Types.HttpError,
    Parameters<typeof fetchPartnerTariffGroupTariffDelete>
  >,
) {
  return useMutation((args) => fetchPartnerTariffGroupTariffDelete(...args), {
    retry: 0,
    ...options,
  });
}
