import { FC } from 'react';
import { Empty, Spin } from 'antd';
import { useClientQuery } from 'features/clients/queries';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { usePartnerId } from 'core/providers/PartnerProvider';
import {
  TransactionsTable,
  useTransactionTableColumns,
} from 'views/Transactions';

import styles from './TransactionsTab.module.css';

export const TransactionsTab: FC = ({}) => {
  const { clientId = '' } = useParams<{ clientId?: string }>();
  const partnerId = usePartnerId();
  const {
    isError,
    isLoading,
    data: client,
  } = useClientQuery(clientId, {
    enabled: !!clientId,
  });

  const { t } = useTranslation();

  const tableColumns = useTransactionTableColumns().filter((column) => {
    return column.key !== 'clientId';
  });

  if (isLoading) {
    return <Spin />;
  }

  if (isError) {
    return <div className={styles.container}>{t('common.status.failed')}</div>;
  }

  if (!client) {
    return <Empty className={styles.container} />;
  }

  return (
    <TransactionsTable
      clientId={clientId}
      partnerId={partnerId}
      tableColumns={tableColumns}
    />
  );
};
