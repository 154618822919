import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Table, Descriptions, Empty } from 'antd';
import { ResTransactionMetrics } from 'features/transactions/types/responses';

import { useMetricsColumns } from './useMetricsColumns';

interface Props {
  metrics?: ResTransactionMetrics;
}

export const Metrics: FC<Props> = ({ metrics }) => {
  const { t } = useTranslation();
  const metricsColumns = useMetricsColumns();

  if (!metrics?.items?.length) {
    return <Empty />;
  }

  return (
    <>
      <Descriptions title={t('transactions.titles.metrics')} />
      <Table
        size="small"
        pagination={false}
        scroll={{ x: 'max-content' }}
        dataSource={metrics.items}
        columns={metricsColumns}
      />
    </>
  );
};
