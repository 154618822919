import * as Types from 'core/types';
import { useMutation, UseMutationOptions } from '@tanstack/react-query';

import { fetchPartnerTariffGroupCreate } from '../fetches';
import { TariffGroup } from '../types';

export function usePartnerTariffGroupCreateMutation(
  options?: UseMutationOptions<
    TariffGroup,
    Types.HttpError,
    Parameters<typeof fetchPartnerTariffGroupCreate>
  >,
) {
  return useMutation((args) => fetchPartnerTariffGroupCreate(...args), {
    retry: 0,
    ...options,
  });
}
