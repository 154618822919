import { useEffect, useMemo, useRef, useState } from 'react';
import { Button, Form, Modal, Row, Segmented, SegmentedProps } from 'antd';
import { useTranslation } from 'react-i18next';
import { CSSTransition, SwitchTransition } from 'react-transition-group';

import type {
  ElementFormMode,
  ElementFormValues,
  ElementModalProps,
} from './types';
import './styles.css';
import { fromFormValues, toFormValues } from './utils';
import { SimpleFormInputs } from './components/SimpleFormInputs';
import { AdvancedFormInputs } from './components/AdvancedFormInputs';

export const ElementModal = ({
  isOpen,
  onCancel,
  onSuccess,
  element,
  isReadOnly,
}: ElementModalProps) => {
  const [mode, setMode] = useState<ElementFormMode>('simple');

  const [form] = Form.useForm<ElementFormValues>();
  const { t } = useTranslation();

  const simpleModeRef = useRef<HTMLDivElement | null>(null);
  const advancedModeRef = useRef<HTMLDivElement | null>(null);
  const nodeRef = mode === 'simple' ? simpleModeRef : advancedModeRef;

  const modeSegmentedOptions: SegmentedProps<ElementFormMode>['options'] =
    useMemo(
      () => [
        {
          label: t('pricing.titles.simple-mode'),
          value: 'simple',
        },
        {
          label: t('pricing.titles.advanced-mode'),
          value: 'advanced',
        },
      ],
      [t],
    );

  const resetModalState = () => {
    form.resetFields();
    setMode('simple');
  };

  const onModalCancel = () => {
    resetModalState();
    onCancel?.();
  };

  const onFormSubmit = (values: ElementFormValues) => {
    if (!element) {
      return;
    }

    resetModalState();
    onSuccess(fromFormValues(values, element, mode));
  };

  useEffect(() => {
    if (element) {
      form.setFieldsValue(toFormValues(element));
    }
  }, [element, form]);

  return (
    <Modal
      open={isOpen}
      onCancel={onModalCancel}
      width={600}
      destroyOnClose
      footer={[
        <Button key={'cancel'} onClick={onModalCancel}>
          {t('common.actions.cancel')}
        </Button>,
        <Button
          key={'submit'}
          type={'primary'}
          htmlType={'submit'}
          form="element-form"
        >
          {t('common.actions.save')}
        </Button>,
      ]}
    >
      <Row justify={'center'} style={{ marginBottom: 16 }}>
        <Segmented<ElementFormMode>
          options={modeSegmentedOptions}
          onChange={(value) => {
            setMode(value);
          }}
        />
      </Row>

      <SwitchTransition mode="out-in">
        <CSSTransition
          key={mode}
          nodeRef={nodeRef}
          addEndListener={(done) => {
            nodeRef?.current?.addEventListener('transitionend', done, false);
          }}
          classNames="element-mode"
        >
          <Form
            form={form}
            id="element-form"
            onFinish={onFormSubmit}
            layout={'vertical'}
            disabled={isReadOnly}
          >
            <div ref={nodeRef}>
              {mode === 'simple' && <SimpleFormInputs />}
              {mode === 'advanced' && <AdvancedFormInputs />}
            </div>
          </Form>
        </CSSTransition>
      </SwitchTransition>
    </Modal>
  );
};
